<template>
  <div class="trustedby">
    <h2>Trusted by</h2>
    <h4>
      <b>Industry leading</b> projects use Bubblemaps to follow holders
      behavior, get actionable market insights, and showcase transparency to the
      community.
    </h4>
    <div class="trustedby__logos">
      <span class="trustedby__logos-line">
        <a
          href="https://twitter.com/ape_swap/status/1445121846253441027"
          target="_blank"
        >
          <img alt="Apeswap logo" src="@/assets/logos_white/apeswap.png" />
        </a>
        <a
          href="https://twitter.com/ReefDeFi/status/1447627401631092739?s=20"
          target="_blank"
        >
          <img alt="Reef logo" src="@/assets/logos_white/reef.png" />
        </a>
        <a
          href="https://twitter.com/ArivaCoin/status/1486783602633449483"
          target="_blank"
        >
          <img alt="Ariva logo" src="@/assets/logos_white/ariva.png" />
        </a>
        <a
          href="https://twitter.com/coin98_wallet/status/1450657983923326983"
          target="_blank"
        >
          <img alt="Coin98 logo" src="@/assets/logos_white/coin98.png" />
        </a>
        <a
          href="https://twitter.com/EverRise/status/1440045337670926347"
          target="_blank"
        >
          <img alt="EverRise logo" src="@/assets/logos_white/everrise.png" />
        </a>
      </span>
      <span class="trustedby__logos-line">
        <a
          href="https://twitter.com/beefyfinance/status/1483985127742922752"
          target="_blank"
        >
          <img alt="Beefy logo" src="@/assets/logos_white/beefyfinance.png" />
        </a>
        <a
          href="https://twitter.com/orbs_network/status/1493166028624482310"
          target="_blank"
        >
          <img alt="Orbs logo" src="@/assets/logos_white/orbs.png" />
        </a>
        <a
          href="https://twitter.com/StakeDAOHQ/status/1521425479017848833"
          target="_blank"
        >
          <img alt="StakeDAO logo" src="@/assets/logos_white/stakedao.png" />
        </a>
        <a
          href="https://twitter.com/BlackpoolHQ/status/1521425367696818176"
          target="_blank"
        >
          <img alt="Blackpool logo" src="@/assets/logos_white/blackpool.png" />
        </a>
        <a
          href="https://twitter.com/paraswap/status/1521864410951729154"
          target="_blank"
        >
          <img alt="Paraswap logo" src="@/assets/logos_white/paraswap.png" />
        </a>
        <a
          href="https://twitter.com/parsiq_net/status/1526540539864260608"
          target="_blank"
        >
          <img alt="ParsiQ logo" src="@/assets/logos_white/parsiq.png" />
        </a>
      </span>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/variables";

.trustedby {
  text-align: center;

  h4 {
    opacity: 0.9;
  }

  .trustedby__button {
    margin-top: 15px;
  }

  .trustedby__logos {
    margin-top: 25px;

    .trustedby__logos-line {
      margin-top: 15px;
      white-space: nowrap;
      display: inline-block;
      margin-right: 7px;
      margin-left: 7px;
    }
    a {
      opacity: 0.8;
      transition: opacity 200ms ease-in-out;

      &:not(:last-child) {
        margin-right: 14px;
      }

      img {
        height: 45px;
        transition: transform 200ms ease-in-out;
      }

      &:hover {
        opacity: 1;

        img {
          transform: translateY(-6px);
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .trustedby {
    .trustedby__logos {
      a {
        img {
          height: 40px;
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .trustedby {
    .trustedby__logos {
      a {
        &:not(:last-child) {
          margin-right: 10px;
        }
        img {
          height: 35px;
        }
      }
    }
  }
}

@media (max-width: 350px) {
  .trustedby {
    .trustedby__logos {
      a {
        img {
          height: 25px;
        }
      }
    }
  }
}
</style>
