<template>
  <Tweet
    tweet_link="https://twitter.com/KyberNetwork/status/1545287852107280386"
    image_name="kybernetwork.jpeg"
    name="Kyber Network"
    handle="@KyberNetwork"
    is_verified
    is_gold
  >
    At #KyberSwap, we love efficiency & simplicity.<br /><br />Which is why we
    are proud to have $KNC listed on <b>@bubblemaps</b>, an incredible
    visualization tool that combines both!&nbsp;🛠
  </Tweet>
</template>

<script>
import Tweet from "@/components/atoms/Tweet.vue";

export default {
  components: {
    Tweet,
  },
};
</script>
