<template>
  <svg fill="none" viewBox="0 0 20 20">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 18c2.1217 0 4.1566-.8429 5.6569-2.3431C17.1571 14.1566 18 12.1217 18 10c0-2.12173-.8429-4.15656-2.3431-5.65685C14.1566 2.84285 12.1217 2 10 2c-2.12173 0-4.15656.84285-5.65685 2.34315C2.84285 5.84344 2 7.87827 2 10c0 2.1217.84285 4.1566 2.34315 5.6569C5.84344 17.1571 7.87827 18 10 18ZM9.555 7.168c-.1506-.10048-.32565-.15819-.50648-.16697-.18083-.00879-.36066.03169-.52029.1171-.15963.08541-.29307.21255-.3861.36786C8.04909 7.6413 7.99997 7.81896 8 8v4c-.00003.181.04909.3587.14213.514.09303.1553.22647.2825.3861.3679.15963.0854.33946.1259.52029.1171.18083-.0088.35588-.0665.50648-.167l3-2c.137-.0913.2493-.215.3269-.3602.0777-.1451.1183-.3072.1183-.4718 0-.16461-.0406-.32668-.1183-.47182-.0776-.14514-.1899-.26886-.3269-.36018l-3-2Z"
    />
  </svg>
</template>

<script>
export default { name: "PlayIcon" };
</script>
