<template>
  <div class="footer">
    <div class="mdc-layout-grid">
      <footer class="mdc-layout-grid__inner">
        <div
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-desktop"
        >
          <div class="footer__logo-container">
            <SvgBubblemapsLogo class="footer__logo" />
            <SvgBubblemapsText class="footer__logotext" />
          </div>
          <div class="footer__subtitle">
            Innovative Visuals for Blockchain Data
          </div>
          <div class="footer__socials">
            <a target="_blank" href="https://twitter.com/bubblemaps">
              <SvgTwitterLogo class="footer__social" />
            </a>
            <a target="_blank" href="https://t.me/bubblemaps">
              <SvgTelegramLogo class="footer__social" />
            </a>
            <a target="_blank" href="https://discord.gg/bubblemaps">
              <SvgDiscordLogo class="footer__social footer__social--discord" />
            </a>
            <a target="_blank" href="https://www.instagram.com/bubblemaps.io/">
              <SvgInstagramLogo
                class="footer__social footer__social--instagram"
              />
            </a>
            <a target="_blank" href="https://www.youtube.com/c/Bubblemaps/">
              <SvgYoutubeLogo class="footer__social" />
            </a>
          </div>
          <div class="footer__policies --left">
            <a
              target="_blank"
              href="https://storage.googleapis.com/public-bubblemaps/legal/Terms%20%26%20Conditions%20-%20Bubblemaps.io.pdf"
            >
              Terms & Conditions
            </a>
            -
            <a
              target="_blank"
              href="https://storage.googleapis.com/public-bubblemaps/legal/Cookies%20-%20Bubblemaps.io.pdf"
            >
              Cookies
            </a>
            -
            <a
              target="_blank"
              href="https://storage.googleapis.com/public-bubblemaps/legal/Privacy%20Policy%20-%20%20Bubblemaps.io.pdf"
            >
              Privacy
            </a>
          </div>
        </div>
        <div
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-2 mdc-layout-grid__cell--span-2-desktop"
        >
          <h5>Product</h5>
          <a target="_blank" href="https://app.bubblemaps.io/">App</a>
          <router-link to="/case-studies">Case Studies</router-link>
          <router-link to="/get-premium">Get Premium</router-link>
          <a
            target="_blank"
            href="https://docs.google.com/forms/d/e/1FAIpQLSdj9s9DhslOWlgv1xaJRlOAAv-3lYOzR7W9IWpnv0v0b2EFog/viewform"
          >
            Get Listed
          </a>
          <a target="_blank" href="https://blog.bubblemaps.io/">Blog</a>
        </div>
        <div
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-2 mdc-layout-grid__cell--span-2-desktop"
        >
          <h5>
            Supported<br />
            Chains
          </h5>
          <a target="_blank" href="https://app.bubblemaps.io/eth">Ethereum</a>
          <a target="_blank" href="https://app.bubblemaps.io/bsc">BNB Chain</a>
          <a target="_blank" href="https://app.bubblemaps.io/ftm">Fantom</a>
          <a target="_blank" href="https://app.bubblemaps.io/avax">Avalanche</a>
          <a target="_blank" href="https://app.bubblemaps.io/cro">Cronos</a>
          <a target="_blank" href="https://app.bubblemaps.io/arbi">Arbitrum</a>
          <a target="_blank" href="https://app.bubblemaps.io/poly">Polygon</a>
          <a target="_blank" href="https://app.bubblemaps.io/base">Base</a>
          <a target="_blank" href="https://app.bubblemaps.io/sol">Solana</a>
        </div>
        <div
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-2 mdc-layout-grid__cell--span-2-desktop"
        >
          <h5>Integrations</h5>
          <a target="_blank" href="https://etherscan.io/">Etherscan</a>
          <a target="_blank" href="https://pro.opensea.io/">OpenSea</a>
          <a target="_blank" href="https://dexscreener.com/">DEX Screener</a>
          <a target="_blank" href="https://www.geckoterminal.com/"
            >GeckoTerminal</a
          >
          <a target="_blank" href="https://pump.fun/">Pump.fun</a>
        </div>
        <div
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-2 mdc-layout-grid__cell--span-2-desktop"
        >
          <h5>Contact</h5>
          <router-link to="/work-with-us">Work with Us</router-link>
          <a target="_blank" href="https://discord.gg/bubblemaps">Support</a>
          <a target="_blank" href="mailto:contact@bubblemaps.io">Email</a>
        </div>
        <div
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12 footer__policies --bottom"
        >
          <div>
            <a
              target="_blank"
              href="https://storage.googleapis.com/public-bubblemaps/legal/Terms%20%26%20Conditions%20-%20Bubblemaps.io.pdf"
            >
              Terms & Conditions
            </a>
            -
            <a
              target="_blank"
              href="https://storage.googleapis.com/public-bubblemaps/legal/Cookies%20-%20Bubblemaps.io.pdf"
            >
              Cookies
            </a>
            -
            <a
              target="_blank"
              href="https://storage.googleapis.com/public-bubblemaps/legal/Privacy%20Policy%20-%20%20Bubblemaps.io.pdf"
            >
              Privacy
            </a>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped lang="scss">
@import "src/assets/variables";

.footer {
  background-color: $theme-almost-black;
  color: $theme-light-grey;
  font-size: 0.8rem;
  padding-top: 60px;
  padding-bottom: 40px;
  a {
    margin-bottom: 8px;

    &:hover {
      color: $theme-blue;
    }
  }

  .mdc-layout-grid__cell {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  h5 {
    margin-top: 0;
    color: white;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1.1em;
  }
}

.footer__subtitle {
  text-transform: uppercase;
  letter-spacing: 0.15rem;
  font-size: 0.75rem;
}

.footer__socials {
  margin-top: 12px;
  display: flex;
  align-items: center;

  a {
    background-color: rgba($theme-blue, 0.3);
    transition: background-color 150ms linear;
    width: 35px;
    height: 35px;
    margin-right: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;

    .footer__social {
      height: 24px;
      fill: white;

      &.footer__social--instagram {
        height: 20px;
      }

      &.footer__social--discord {
        height: 18px;
        position: relative;
        top: -1px;
      }

      &.footer__social--email {
        height: 22px;
      }
    }

    &:hover {
      background-color: $theme-blue;
    }
  }
}

.footer__policies {
  margin-top: 10px;
  opacity: 0.7;
  font-size: 0.9em;

  &.--left {
    display: none;
  }
}

.footer__logo-container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  .footer__logo {
    height: 20px;
  }
  .footer__logotext {
    height: 20px;
    margin-left: 10px;
    position: relative;
    top: 1px;
    fill: white;
  }
}

@media (min-width: $mdc-grid-desktop) {
  .footer {
    .mdc-layout-grid__cell {
      align-items: flex-start;
      text-align: left;
    }
  }

  .footer__policies {
    &.--left {
      display: initial;
    }

    &.--bottom {
      display: none;
    }
  }
}

@media (min-width: $mdc-desktop) {
  .footer {
    font-size: 0.9rem;
  }
}
</style>
