<template>
  <form @submit.prevent="onSubmit" class="mailing-form">
    <label for="email" class="mailing-form__left">
      <div class="mailing-form__title">Let's be friends</div>
      <div class="mailing-form__subtitle">
        Sign up to get product updates and hot investigations!<br />You can
        unsubscribe at any time.
      </div>
    </label>
    <div class="mailing-form__right">
      <div class="mailing-form__consent">
        By signing up, I agree to receive emails from Bubblemaps.
      </div>
      <div
        id="signup-form-container"
        style="width: 100%; border-radius: 8px; overflow: hidden"
      ></div>
    </div>
  </form>
</template>

<script>
export default {
  name: "SubscribeForm",
  data() {
    return {
      email: "",
      error: "",
      loading: false,
      success: false,
    };
  },
  mounted() {
    const script = document.createElement("script");
    script.src =
      "https://cdn.jsdelivr.net/ghost/signup-form@~0.1/umd/signup-form.min.js";
    script.setAttribute("data-label-1", "WebsiteForm");
    script.setAttribute("data-button-color", "#de219a");
    script.setAttribute("data-button-text-color", "#FFFFFF");
    script.setAttribute("data-site", "https://blog.bubblemaps.io/");
    script.setAttribute("data-locale", "en");
    script.async = true;

    document.getElementById("signup-form-container").appendChild(script);
  },
  methods: {
    onSubmit: function () {
      this.loading = true;
      this.success = false;
      this.error = "";
      this.$store.getters.get_db
        .collection("website_form")
        .add({
          email: this.email,
          dt_added: new Date(),
        })
        .then(() => {
          this.error = "";
          this.success = true;
          this.loading = false;
        })
        .catch((error) => {
          this.error = error;
          this.success = false;
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/variables";

.mailing-form {
  @include theme-glass-effect;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .mailing-form__left {
    flex-shrink: 0;
  }

  .mailing-form__right {
    flex-shrink: 1;
    flex-grow: 1;
    margin-left: 30px;
  }

  .mailing-form__title {
    font-size: 2rem;
    font-weight: 800;
    margin-bottom: 8px;
  }

  .mailing-form__content {
    display: flex;
    justify-content: flex-end;

    .mailing-form__input {
      width: 100%;
      max-width: 250px;
      height: 30px;
      border-radius: 30px;
      padding-left: 12px;
      border: 1px solid rgba($theme-pink, 0.5);
      background: transparent;
      font-family: "Montserrat", sans-serif;
      font-weight: 600;
      color: white;

      &:focus {
        border: 1px solid rgba($theme-pink, 1);
        outline: none;
      }

      &:required {
        box-shadow: none;
      }
    }

    input.mailing-form__input {
      color: white;
    }

    .mailing-form__button {
      display: flex;
      align-items: center;
      height: 32px;
      border-radius: 100px;
      border: none;
      margin-left: 10px;
      @include theme-pink-gradient(45deg);

      svg {
        fill: white;
        height: 20px;
      }
    }
  }

  .mailing-form__consent {
    opacity: 0.6;
    margin-bottom: 4px;
    font-size: 0.8em;
  }
}

@media (max-width: 870px) {
  .mailing-form {
    flex-direction: column;
    text-align: center;

    .mailing-form__right {
      margin-left: 0;
      margin-top: 20px;
      width: 100%;
      text-align: center;
    }

    .mailing-form__content {
      justify-content: center;
    }
  }
}
</style>
