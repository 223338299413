<template>
  <div class="mdc-typography bubblemaps-mdc">
    <Navigation />
    <div class="bubblemaps-page-content">
      <router-view />
    </div>
    <Footer />
  </div>
</template>

<script>
import Navigation from "@/components/organisms/Navigation/Navigation.vue";
import Footer from "@/components/organisms/Footer/Footer.vue";

export default {
  components: {
    Navigation,
    Footer,
  },
};
</script>

<style lang="scss">
@use "sass:map";
@use "@material/theme" with (
  $primary: #8d26d8,
  $secondary: #de219a,
  $background: #100c17,
  $surface: #271644,
  $on-surface: #ffffff
);

@import "src/assets/variables";

// Import styles for MDC Web components
@import "@material/layout-grid/mdc-layout-grid";
@import "@material/typography/mdc-typography";
@import "@material/ripple/mdc-ripple";
@import "@material/button/mdc-button";
@import "@material/drawer/mdc-drawer";
@import "@material/icon-button/mdc-icon-button";
@import "@material/list/mdc-list";
@import "@material/top-app-bar/mdc-top-app-bar";
@import "@material/dialog/mdc-dialog";

// Twitter Font
@font-face {
  font-family: "TwitterChirp";
  src: url("https://abs.twimg.com/fonts/v2/chirp-regular-web.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "TwitterChirp";
  src: url("https://abs.twimg.com/fonts/v2/chirp-heavy-web.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none; /* IE and Edge */
  // For some reason the following line shows the bar on chrome
  // scrollbar-width: none; /* Firefox */
}

:root {
  color-scheme: dark;
}

::-webkit-scrollbar {
  height: 12px;
  width: 12px;
  background: black;
}

::-webkit-scrollbar-thumb {
  background: $theme-dark-pink;
  -webkit-border-radius: 2ex;
  border: 2px solid black;
}

.bubblemaps-mdc {
  min-height: 100vh;
  margin: 0;
  color: white;
  background: $theme-almost-black;

  a {
    color: inherit;
    text-decoration: inherit;
  }

  p {
    a {
      color: $theme-blue;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .mdc-layout-grid {
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
  }

  @media (min-width: $mdc-layout-grid-max-width) {
    .mdc-layout-grid {
      width: $mdc-layout-grid-max-width;
    }
  }
}

.mdc-typography {
  color: white;
}

/* Adjustements for top app bar display */
.bubblemaps-page-content {
  padding-top: 56px;
}

/* Yes, mdc top app bar hase a different breakpoint than mdc grid */
@media (min-width: 600px) {
  .bubblemaps-page-content {
    padding-top: 64px;
  }
}

h2 {
  margin-top: 0;
  font-size: 1.8rem;
}
h4 {
  font-size: 1rem;
  max-width: 800px;
  margin: auto;
  font-weight: 400;
  opacity: 0.7;
}

@media (min-width: $mdc-desktop) {
  h2 {
    font-size: 2.2rem;
  }
  h4 {
    font-size: 1.1rem;
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>
