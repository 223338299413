import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import firebase from "firebase/app";
import "firebase/firestore";
import { clipboard } from "./plugins/clipboard";
import ScrollReveal from "scrollreveal";
import BubblemapsLogo from "./components/svgs/BubblemapsLogo.vue";
import BubblemapsText from "./components/svgs/BubblemapsText.vue";
// ICONS
import BurgerIcon from "./components/svgs/icons/Burger.vue";
import AtIcon from "./components/svgs/icons/At.vue";
import RightArrowIcon from "./components/svgs/icons/RightArrow.vue";
import LoadingIcon from "./components/svgs/icons/Loading.vue";
import PlayIcon from "./components/svgs/icons/Play.vue";
import DataIcon from "./components/svgs/icons/Data.vue";
import NetworkIcon from "./components/svgs/icons/Network.vue";
import VerifiedIcon from "./components/svgs/icons/Verified.vue";
import CodeIcon from "./components/svgs/icons/Code.vue";
import TwitterVerifiedIcon from "./components/svgs/icons/TwitterVerified.vue";
// LOGOS
import TwitterLogo from "./components/svgs/logos/Twitter.vue";
import InstagramLogo from "./components/svgs/logos/Instagram.vue";
import DiscordLogo from "./components/svgs/logos/Discord.vue";
import TelegramLogo from "./components/svgs/logos/Telegram.vue";
import YoutubeLogo from "./components/svgs/logos/Youtube.vue";
import TiktokLogo from "./components/svgs/logos/Tiktok.vue";
import PancakeswapLogo from "./components/svgs/logos/Pancakeswap.vue";
import ApeswapLogo from "./components/svgs/logos/Apeswap.vue";

const inline_svgs = [
  BubblemapsLogo,
  BubblemapsText,
  // ICONS
  BurgerIcon,
  AtIcon,
  RightArrowIcon,
  LoadingIcon,
  PlayIcon,
  DataIcon,
  NetworkIcon,
  VerifiedIcon,
  CodeIcon,
  TwitterVerifiedIcon,
  // LOGOS
  TwitterLogo,
  InstagramLogo,
  DiscordLogo,
  TelegramLogo,
  YoutubeLogo,
  TiktokLogo,
  PancakeswapLogo,
  ApeswapLogo,
];

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyBc-Gns-Nf0c47NIk02WVVe1xc66HQukXU",
  authDomain: "cryptos-tools.firebaseapp.com",
  projectId: "cryptos-tools",
  storageBucket: "cryptos-tools.appspot.com",
  messagingSenderId: "752909939035",
  appId: "1:752909939035:web:0d681b42c25eb1bc622a7e",
});

const db = firebaseApp.firestore();
store.dispatch("init_metadata", db);

const app = createApp(App).use(store).use(router);

inline_svgs.forEach((item) => {
  app.component(`Svg${item.name}`, item);
});

// make v-scrollreveal usable in all components
app.directive("scrollreveal", {
  mounted: (el, binding) => {
    const defaultTransition = {
      distance: "100px",
      origin: "bottom",
      duration: 1000,
    };
    if (binding.value) {
      ScrollReveal().reveal(el, { ...defaultTransition, ...binding.value });
    } else {
      ScrollReveal().reveal(el, defaultTransition);
    }
  },
  beforeUnmount: (el) => {
    ScrollReveal().clean(el);
  },
});

app.config.globalProperties.$clipboard = clipboard;

app.mount("#app");
