<template>
  <svg viewBox="0 0 48 48">
    <path
      d="M34.1 35.6q-.7.7-1.675.65-.975-.05-1.625-.7-.7-.7-.7-1.7t.7-1.65l8.25-8.25-8.2-8.2q-.7-.7-.675-1.675.025-.975.725-1.675.7-.7 1.7-.7t1.7.7l9.8 9.85q.7.7.7 1.65t-.7 1.65Zm-20.3-.25-9.9-9.8q-.7-.7-.7-1.65t.7-1.65l10-10.05q.7-.7 1.7-.675 1 .025 1.7.675.65.7.65 1.7t-.65 1.65L9 23.85 17.15 32q.7.75.725 1.7.025.95-.675 1.65-.75.7-1.725.7-.975 0-1.675-.7Z"
    />
  </svg>
</template>

<script>
export default { name: "CodeIcon" };
</script>
