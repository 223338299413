<template>
  <Tweet
    tweet_link="https://twitter.com/avalancheavax/status/1567228087212834816"
    image_name="avalanche.jpeg"
    name="Avalanche"
    handle="@avalancheavax"
    is_verified
    is_gold
  >
    <b>@bubblemaps</b> is a data visualization platform that makes research and
    analysis more accessible and convenient for users.<br />
    Check out their new #Avalanche 🔺 integration!
  </Tweet>
</template>

<script>
import Tweet from "@/components/atoms/Tweet.vue";

export default {
  components: {
    Tweet,
  },
};
</script>
