<template>
  <div class="testimonials">
    <h2>Testimonials</h2>
    <!-- 4 COLS -->
    <div
      class="mdc-layout-grid__inner testimonials-4-cols sr_prevent_flickering"
    >
      <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-3">
        <Fantom v-scrollreveal />
        <Kyber v-scrollreveal="{ delay: 200 }" />
        <Cronos v-scrollreveal="{ delay: 250 }" />
        <div
          v-scrollreveal="{ delay: 300 }"
          class="testimonials__gradient"
        ></div>
      </div>
      <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-3">
        <Opensea v-scrollreveal="{ delay: 100 }" />
        <Everrise v-scrollreveal="{ delay: 200 }" />
        <Rugdoc v-scrollreveal="{ delay: 250 }" />
        <div
          v-scrollreveal="{ delay: 300 }"
          class="testimonials__gradient --long"
        ></div>
      </div>
      <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-3">
        <Bnbchain v-scrollreveal />
        <Solana v-scrollreveal="{ delay: 200 }" />
        <Reef v-scrollreveal="{ delay: 250 }" />
        <div
          v-scrollreveal="{ delay: 300 }"
          class="testimonials__gradient"
        ></div>
      </div>
      <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-3">
        <Avalanche v-scrollreveal="{ delay: 100 }" />
        <Apeswap v-scrollreveal="{ delay: 200 }" />
        <Olympus v-scrollreveal="{ delay: 250 }" />
        <div
          v-scrollreveal="{ delay: 300 }"
          class="testimonials__gradient --long"
        ></div>
      </div>
    </div>
    <!-- 3 COLS -->
    <div class="mdc-layout-grid__inner testimonials-3-cols">
      <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4">
        <Fantom v-scrollreveal />
        <Kyber v-scrollreveal="{ delay: 200 }" />
        <Cronos v-scrollreveal="{ delay: 250 }" />
        <div
          v-scrollreveal="{ delay: 300 }"
          class="testimonials__gradient"
        ></div>
      </div>
      <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4">
        <Opensea v-scrollreveal="{ delay: 100 }" />
        <Bnbchain v-scrollreveal="{ delay: 200 }" />
        <Solana v-scrollreveal="{ delay: 250 }" />
        <div
          v-scrollreveal="{ delay: 300 }"
          class="testimonials__gradient"
        ></div>
      </div>
      <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4">
        <Avalanche v-scrollreveal />
        <Apeswap v-scrollreveal="{ delay: 200 }" />
        <Reef v-scrollreveal="{ delay: 250 }" />
        <div
          v-scrollreveal="{ delay: 300 }"
          class="testimonials__gradient --long"
        ></div>
      </div>
    </div>
    <!-- 2 COLS -->
    <div class="mdc-layout-grid__inner testimonials-2-cols">
      <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4">
        <Fantom v-scrollreveal />
        <Bnbchain v-scrollreveal="{ delay: 100 }" />
        <Solana v-scrollreveal="{ delay: 200 }" />
        <Cronos v-scrollreveal="{ delay: 250 }" />
        <div
          v-scrollreveal="{ delay: 300 }"
          class="testimonials__gradient --long"
        ></div>
      </div>
      <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4">
        <Opensea v-scrollreveal />
        <Avalanche v-scrollreveal="{ delay: 100 }" />
        <Apeswap v-scrollreveal="{ delay: 200 }" />
        <Reef v-scrollreveal="{ delay: 250 }" />
        <div
          v-scrollreveal="{ delay: 300 }"
          class="testimonials__gradient"
        ></div>
      </div>
    </div>
    <!-- 1 COL -->
    <div class="mdc-layout-grid__inner testimonials-1-cols">
      <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4">
        <Fantom v-scrollreveal />
        <Opensea v-scrollreveal />
        <Avalanche v-scrollreveal="{ delay: 100 }" />
        <Cronos v-scrollreveal="{ delay: 200 }" />
        <Solana v-scrollreveal="{ delay: 230 }" />
        <Bnbchain v-scrollreveal="{ delay: 260 }" />
        <div
          v-scrollreveal="{ delay: 290 }"
          class="testimonials__gradient"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import Opensea from "./parts/Opensea.vue";
import Solana from "./parts/Solana.vue";
import Reef from "./parts/Reef.vue";
import Everrise from "./parts/Everrise.vue";
import Apeswap from "./parts/Apeswap.vue";
import Bnbchain from "./parts/Bnbchain.vue";
import Fantom from "./parts/Fantom.vue";
import Avalanche from "./parts/Avalanche.vue";
import Rugdoc from "./parts/Rugdoc.vue";
import Olympus from "./parts/Olympus.vue";
import Kyber from "./parts/Kyber.vue";
import Cronos from "./parts/Cronos.vue";

export default {
  name: "Testimonials",
  components: {
    Opensea,
    Solana,
    Reef,
    Everrise,
    Apeswap,
    Bnbchain,
    Fantom,
    Avalanche,
    Rugdoc,
    Olympus,
    Kyber,
    Cronos,
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/variables";

.testimonials {
  h2 {
    text-align: center;
  }

  .mdc-layout-grid__cell {
    a {
      margin-bottom: 22px;
    }
  }

  .testimonials__gradient {
    height: 60px;
    background: linear-gradient(180deg, #ffffff17 0%, #ffffff00 100%);
    border-radius: 15px 15px 0 0;

    &.--long {
      height: 90px;
    }

    &.--short {
      height: 40px;
    }
  }
}

@media (max-width: calc($mdc-grid-desktop - 1px)) {
  .testimonials {
    .mdc-layout-grid__cell {
      a {
        margin-bottom: 16px;
      }
    }
  }
}

$breakpoint-4cols: 1100px;

@media (max-width: calc($mdc-grid-tablet - 1px)) {
  .testimonials-1-cols {
    display: grid;
  }
  .testimonials-2-cols {
    display: none;
  }
  .testimonials-3-cols {
    display: none;
  }
  .testimonials-4-cols {
    display: none;
  }
}

@media (min-width: $mdc-grid-tablet) and (max-width: calc($mdc-grid-desktop - 1px)) {
  .testimonials-1-cols {
    display: none;
  }
  .testimonials-2-cols {
    display: grid;
  }
  .testimonials-3-cols {
    display: none;
  }
  .testimonials-4-cols {
    display: none;
  }
}

@media (min-width: $mdc-grid-desktop) and (max-width: calc($breakpoint-4cols - 1px)) {
  .testimonials-1-cols {
    display: none;
  }
  .testimonials-2-cols {
    display: none;
  }
  .testimonials-3-cols {
    display: grid;
  }
  .testimonials-4-cols {
    display: none;
  }
}

@media (min-width: $breakpoint-4cols) {
  .testimonials-1-cols {
    display: none;
  }
  .testimonials-2-cols {
    display: none;
  }
  .testimonials-3-cols {
    display: none;
  }
  .testimonials-4-cols {
    display: grid;
  }
}
</style>
