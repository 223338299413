<template>
  <svg viewBox="0 0 48 48">
    <path
      d="m12.7 39.6-6-1.25q-.9-.15-1.45-.9-.55-.75-.4-1.65L5.5 30l-3.8-4.45q-.6-.65-.6-1.55t.6-1.55l3.8-4.4-.65-5.8q-.15-.95.4-1.675.55-.725 1.45-.925l6-1.2 3-5.2q.45-.75 1.35-1.1.9-.35 1.7.1L24 4.7l5.25-2.45q.8-.45 1.7-.15.9.3 1.35 1.1l3.05 5.25 5.95 1.2q.9.2 1.45.925.55.725.45 1.675l-.7 5.8 3.8 4.4q.6.65.6 1.55t-.6 1.55L42.5 30l.7 5.8q.1.9-.45 1.65-.55.75-1.45.9l-5.95 1.25-3.05 5.2q-.45.8-1.35 1.1-.9.3-1.7-.15L24 43.3l-5.25 2.45q-.8.45-1.7.1-.9-.35-1.35-1.1ZM24 24Zm-3.8 5.4q.7.7 1.65.7t1.65-.7l8.85-8.75q.5-.5.475-1.225-.025-.725-.575-1.275-.55-.55-1.275-.525-.725.025-1.325.575l-7.8 7.75-3.4-3.65q-.55-.55-1.325-.55-.775 0-1.375.6-.55.6-.575 1.35-.025.75.575 1.3Zm-1.35 11.1L24 38.25l5.3 2.25 3.25-4.85 5.65-1.45-.55-5.8L41.5 24l-3.85-4.5.55-5.8-5.65-1.35L29.2 7.5 24 9.7l-5.3-2.2-3.25 4.85-5.6 1.35.5 5.8L6.5 24l3.85 4.4-.5 5.9 5.6 1.35Z"
    />
  </svg>
</template>

<script>
export default { name: "VerifiedIcon" };
</script>
