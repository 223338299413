<template>
  <!-- DIALOGS -->
  <Dialog ref="tokenDialog">
    <div class="mdc-dialog__content">
      <h3>Moonlight is the utility token of Bubblemaps</h3>
      <div class="dialog__address">
        <b>0xb1ce...f382</b
        ><CopyText
          text="0xb1ced2e320e3f4c8e3511b1dc59203303493f382"
          id="moonlight-contract-copy"
        />
      </div>
      <p>
        The tokenomics include <b>2%</b> reflection and <b>5%</b> generated LP
        for a total of <b>7%</b> transaction fee. Moonlight target LP to
        Marketcap ratio is <b>10%</b>. Extra generated LP can be used as a
        funding mechanism (marketing, technical costs, etc.)
      </p>
      <div class="dialog__swap-buttons">
        <a
          class="dialog__swap-button --pancake"
          target="_blank"
          href="https://pancakeswap.finance/swap?outputCurrency=0xb1ced2e320e3f4c8e3511b1dc59203303493f382"
          ><SvgPancakeswapLogo
        /></a>
      </div>
    </div>
    <div class="mdc-dialog__actions">
      <Button data-mdc-dialog-action="discard" data-mdc-dialog-initial-focus>
        Close
      </Button>
    </div>
  </Dialog>
  <!-- CONTENT -->
  <div class="header">
    <div class="mdc-layout-grid">
      <div class="mdc-layout-grid__inner">
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12 title">
          <div v-scrollreveal class="sr_prevent_flickering">
            <h1>Unlock Premium Membership</h1>
            <h4>
              Unlimited access, comprehensive features,
              infinite&nbsp;possibilities.
            </h4>
          </div>
          <Button
            raised
            v-scrollreveal="{ duration: 2000 }"
            class="sr_prevent_flickering"
            link="https://accounts.bubblemaps.io"
            target="_blank"
          >
            Create Your Account
          </Button>
        </div>
      </div>
    </div>
  </div>
  <div class="getpremium">
    <div class="mdc-layout-grid">
      <div class="mdc-layout-grid__inner">
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
          <h3>Why you should go premium</h3>
        </div>
        <div
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6 sellingpoint"
        >
          <img
            class="sellingpoint__img --left sr_prevent_flickering noselect"
            src="@/assets/illustrations/magnifying-glass.png"
            v-scrollreveal="{ delay: 200 }"
          />
          <div class="sellingpoint__box --left">
            <h4>Investigate</h4>
            <h5>Explore any DeFi token or NFT collection</h5>
          </div>
        </div>
        <div
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6 sellingpoint"
        >
          <div class="sellingpoint__box --right">
            <h4>Time Travel</h4>
            <h5>Access the history of all maps and refresh at any time</h5>
          </div>
          <img
            class="sellingpoint__img --right sr_prevent_flickering noselect"
            src="@/assets/illustrations/clock.png"
            v-scrollreveal="{ delay: 200 }"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="getpremium">
    <div class="mdc-layout-grid">
      <div class="mdc-layout-grid__inner">
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
          <h3>How to get premium?</h3>
        </div>
        <div
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4 premiumstep"
        >
          <div class="premiumstep__number noselect">1.</div>
          <div class="premiumstep__text">Connect your<br />DeFi wallet</div>
          <Button
            outlined
            v-scrollreveal
            class="sr_prevent_flickering"
            link="https://accounts.bubblemaps.io"
            target="_blank"
          >
            Create Account
          </Button>
        </div>
        <div
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4 premiumstep"
        >
          <div class="premiumstep__number noselect">2.</div>
          <div class="premiumstep__text">
            Hold 250 billion<br />Moonlight Tokens
          </div>
          <Button
            outlined
            v-scrollreveal
            class="sr_prevent_flickering"
            @click="$refs.tokenDialog.open()"
          >
            Read More
          </Button>
        </div>
        <div
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4 premiumstep --last"
        >
          <div class="premiumstep__number noselect">3.</div>
          <div class="premiumstep__text">Enjoy unlimited<br />access</div>
          <Button
            outlined
            v-scrollreveal
            class="sr_prevent_flickering"
            link="https://app.bubblemaps.io"
            target="_blank"
          >
            Launch App
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CopyText from "@/components/atoms/CopyText.vue";
import Button from "@/components/atoms/material/Button.vue";
import Dialog from "@/components/atoms/material/Dialog.vue";

export default {
  name: "GetPremium",
  components: {
    Button,
    Dialog,
    CopyText,
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/variables";

.header {
  @include theme-almost-black-gradient(45deg);
  margin-top: -56px;
  padding-top: 56px;
}

@media (min-width: $mdc-tablet) {
  .header {
    margin-top: -64px;
    padding-top: 64px;
  }
}

.title {
  text-align: center;
  padding-top: 70px;
  padding-bottom: 70px;

  h1 {
    margin-top: 0;
    margin-bottom: 1rem;
    @include scaling-title(2.1rem, 540px, 400px);
  }

  h4 {
    margin-bottom: 30px;
  }
}

@media (max-width: $mdc-tablet) {
  .title {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.getpremium {
  padding-top: 70px;
  padding-bottom: 70px;
  background: url("../assets/backgrounds/background2.svg") no-repeat center;
  background-size: cover;

  h3 {
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 2.5rem;
    margin-top: 0;
  }

  .sellingpoint {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .sellingpoint__img {
    &.--left {
      width: 220px;
      margin: 0 15px;
    }
    &.--right {
      width: 210px;
      margin: 0 20px;
    }
  }

  .sellingpoint__box {
    @include theme-glass-effect;
    width: 70%;
    height: 300px;
    &.--left {
      margin-left: -80px;
    }
    &.--right {
      margin-right: -80px;
    }

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 60px;
    box-sizing: border-box;

    h4 {
      font-size: 1.5rem;
      margin: 0;
      text-transform: uppercase;
      letter-spacing: 0.2rem;
    }

    h5 {
      font-size: 1.1rem;
      margin: 1rem 0 0 0;
      font-weight: 600;
      max-width: 270px;
    }
  }

  .premiumstep {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    position: relative;
    background-color: rgba(white, 0.05);
    border-radius: 15px;
    padding: 30px 0;
  }

  .premiumstep__number {
    position: absolute;
    top: 0;
    left: 30px;
    font-size: 90px;
    font-weight: 900;
    color: $theme-pink;
    z-index: 0;
    opacity: 0.5;
  }

  .premiumstep__text {
    z-index: 1;
    margin-bottom: 20px;
    font-size: 1.2rem;
    font-weight: 600;
  }
}

@media (min-width: $mdc-grid-tablet) and (max-width: calc($mdc-grid-desktop - 1px)) {
  .getpremium {
    .sellingpoint {
      grid-column-start: 2;
    }
    .premiumstep.--last {
      grid-column-start: 3;
    }
  }
}

@media (max-width: 1300px) {
  .getpremium {
    .sellingpoint__box {
      height: 260px;
      h4 {
        font-size: 1.3rem;
        letter-spacing: 0.15rem;
      }

      h5 {
        font-size: 1rem;
        margin: 0.8rem 0 0 0;
      }
    }
  }
}

@media (max-width: 1050px) {
  .getpremium {
    .sellingpoint__img {
      &.--left {
        width: 164px;
        margin: 0 8px;
      }
      &.--right {
        width: 160px;
        margin: 0 10px;
      }
    }
    .sellingpoint__box {
      height: 230px;
      &.--left {
        margin-left: -60px;
      }
      &.--right {
        margin-right: -60px;
      }
      padding: 45px;

      h4 {
        font-size: 1.2rem;
      }

      h5 {
        font-size: 0.9rem;
      }
    }
  }
}

@media (max-width: 440px) {
  .getpremium {
    .sellingpoint__img {
      &.--left {
        width: 100px;
        margin: 0 10px;
      }
      &.--right {
        width: 100px;
        margin: 0 10px;
      }
    }
    .sellingpoint__box {
      height: 210px;
      &.--left {
        margin-left: -40px;
      }
      &.--right {
        margin-right: -40px;
      }
      padding: 35px;

      h4 {
        font-size: 1.1rem;
      }

      h5 {
        font-size: 0.8rem;
      }
    }
  }
}

.dialog__swap-buttons {
  display: flex;
  justify-content: center;
}

.dialog__swap-button {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include theme-pink-gradient(0deg);
  border-radius: 100px;
  margin: 20px 10px 0 10px;
  transition: transform 200ms ease-in-out;

  svg {
    height: 50px;
    fill: white;
  }

  &.--pancake {
    svg {
      height: 45px;
    }
  }

  &:hover {
    transform: translateY(-10px);
  }
}

.dialog__address {
  display: flex;
  align-items: center;
  justify-content: center;

  b {
    color: $theme-pink;
  }
}
</style>
