<template>
  <Tweet
    tweet_link="https://twitter.com/EverRise/status/1440045337670926347"
    image_name="everrise.jpeg"
    name="EverRise"
    handle="@EverRise"
  >
    We're proud to be listed on
    <b>@bubblemaps</b>, a great tool that allows DeFi investors to research
    connections between wallets on the blockchain!
  </Tweet>
</template>

<script>
import Tweet from "@/components/atoms/Tweet.vue";

export default {
  components: {
    Tweet,
  },
};
</script>
