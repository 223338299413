<template>
  <Tweet
    tweet_link="https://twitter.com/RugDocIO/status/1459599075460976651"
    image_name="rugdoc.jpeg"
    name="Rugdoc.io"
    handle="@RugDocIO"
    is_verified
  >
    We are SO happy to work with <b>@ape_swap</b> and <b>@bubblemaps</b> in
    keeping the Jungle safer for all!
  </Tweet>
</template>

<script>
import Tweet from "@/components/atoms/Tweet.vue";

export default {
  components: {
    Tweet,
  },
};
</script>
