<template>
  <svg
    id="Block3"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 240 200"
  >
    <defs>
      <radialGradient
        id="radial-gradient"
        cx="120"
        cy="100"
        r="110.45"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#231d2f" />
        <stop offset="1" stop-color="#07050d" />
      </radialGradient>
    </defs>
    <g id="BG"><rect class="cls-1" width="240" height="200" rx="25" /></g>
    <g id="Bubblemaps_Logo" data-name="Bubblemaps Logo">
      <path
        class="cls-2"
        d="M36,22.47a5.57,5.57,0,0,0-.55-1l-.2-.37c-.23-.39-.46-.77-.68-1.16,0,0,0,0,0-.05L34,18.94l-.48-.83-.41-.7a1.69,1.69,0,0,0-.17-.29s0,0,0-.05l-.33-.57a1.91,1.91,0,0,0-.52-.64,1.51,1.51,0,0,0-.35-.23,4.69,4.69,0,0,0-1.9-.49h-.39c-.11.2-.22.41-.31.62a4,4,0,0,0-.39,1.91.12.12,0,0,0,0,.08,1.41,1.41,0,0,0,.11.45c.1.23.24.43.36.65a1.61,1.61,0,0,0,.2.33.29.29,0,0,0,.07.12,0,0,0,0,0,0,0,1.69,1.69,0,0,0,.17.29h0s0,0,0,0a.09.09,0,0,1,0,.09.19.19,0,0,0,.05.1.09.09,0,0,1,.05.09.6.6,0,0,0,.09.13L30,20s-.07.05-.09.09a1.24,1.24,0,0,0,.1.2l.67,1.16c.11.18.2.36.32.54a0,0,0,0,0,0,0c.19.32.37.65.57,1,.1.17.19.35.31.52s.24.44.37.66a2.06,2.06,0,0,0,.89.84,2,2,0,0,0,1.25.17,2,2,0,0,0,1-.52,2.1,2.1,0,0,0,.49-.71A2,2,0,0,0,36,22.47Z"
      />
      <path
        class="cls-2"
        d="M29.45,22.3s0,0,0-.06l-.49-.86-.26-.45-1.18-2c-.24-.43-.49-.85-.73-1.27s-.45-.76-.66-1.14a2,2,0,0,0-.91-.89,4.94,4.94,0,0,0-1.85-.46H23l-.06.1a5.33,5.33,0,0,0-.65,2,2.25,2.25,0,0,0,.09.81,3.56,3.56,0,0,0,.41.8l.78,1.35.8,1.39.8,1.39c.08.12.14.26.23.37s.21.37.31.56a3.12,3.12,0,0,0,.29.45l.05.07a2,2,0,0,0,.49.43h0a1.46,1.46,0,0,0,.48.21l.05,0a2,2,0,0,0,2.3-.91,1.58,1.58,0,0,0,.24-.6,1,1,0,0,0,.05-.43h0c0-.06,0-.12,0-.18A1.84,1.84,0,0,0,29.45,22.3ZM26,23.06Zm.11-.09v0h0Zm.84.85h0Zm1.7-.95h0l0,0S28.66,22.86,28.67,22.87Zm-.19.07v0h0ZM27.05,24.5h0a0,0,0,0,1,0,0Zm.42-.21h0Zm.24-.15a0,0,0,0,0,0,0h0S27.71,24.16,27.71,24.14ZM29,22.67h0Zm-.87-.91h0Zm0,.09v0h0S28.14,21.86,28.15,21.85Zm-1,.57h0Zm-.26.18s0,0,0,0h0S26.89,22.58,26.9,22.6Zm-.75.39h0Zm3,.29s0,0,0,0Zm-3.43,0Zm-.13.09h0A0,0,0,0,1,25.59,23.33Zm.52,1Zm2.09.1h0a.07.07,0,0,0,0-.05v0A0,0,0,0,1,28.2,24.42Zm.08-.06h0Zm.07,0Zm.76-.46h0Z"
      />
      <path
        class="cls-2"
        d="M23.06,22.52a.67.67,0,0,0-.07-.18,2,2,0,0,0-.62-.83,1.55,1.55,0,0,0-.55-.3.16.16,0,0,0-.09,0,1.36,1.36,0,0,0-.41-.09h-.05a1,1,0,0,0-.24,0,.34.34,0,0,0-.14,0l-.17,0-.18.05-.17.05h0a.45.45,0,0,0-.12.06l-.14.06L20,21.4l-.1.08h0a.16.16,0,0,0-.09.07h0a.1.1,0,0,0-.05,0,.32.32,0,0,0-.1.1l-.07.07a.64.64,0,0,0-.1.13,1.1,1.1,0,0,0-.09.14.59.59,0,0,0-.06.14,1.28,1.28,0,0,0-.17.51,1.43,1.43,0,0,0-.07.46h0v.66h0a2.48,2.48,0,0,0,.07.55,3.33,3.33,0,0,0,.1.55s0,.06.06.06a3.23,3.23,0,0,0,.54.11,3.83,3.83,0,0,0,.68.08,3.64,3.64,0,0,0,.66,0,1.51,1.51,0,0,0,.36-.06,1.55,1.55,0,0,0,.51-.18.35.35,0,0,0,.14-.06.53.53,0,0,0,.15-.11.15.15,0,0,0,.09-.06.81.81,0,0,0,.1-.09H22.5a0,0,0,0,0,.05,0,.81.81,0,0,0,.09-.1s0,0,.05,0l0-.07a.5.5,0,0,0,.08-.12l.05-.1h0A.29.29,0,0,0,23,24a.36.36,0,0,0,.07-.17l0-.1a.83.83,0,0,0,0-.15h0a.78.78,0,0,0,0-.26v-.12a1.27,1.27,0,0,0,0-.2A1.5,1.5,0,0,0,23.06,22.52ZM21,22.67h0s0,0,0,0Zm-.15,0h0Zm.11-.31h0Zm-.54.42,0,0Zm2.24-.23h0Zm-1.85,1h0l0,0Zm2,.47h0v0Zm.12-.14,0,0Zm-3.61-1.13h0v0Z"
      />
      <g class="cls-3">
        <path
          class="cls-4"
          d="M44.72,23.75a2.75,2.75,0,0,1-1.43-.37,2.8,2.8,0,0,1-1.39-2.45V16.14a.43.43,0,0,1,.11-.32.45.45,0,0,1,.33-.12.41.41,0,0,1,.31.12.44.44,0,0,1,.12.32v2.93a2.47,2.47,0,0,1,.88-.74,2.53,2.53,0,0,1,1.2-.28,2.64,2.64,0,0,1,1.4.37,2.72,2.72,0,0,1,1,1,3.06,3.06,0,0,1,.35,1.46,2.86,2.86,0,0,1-.38,1.46,2.67,2.67,0,0,1-1,1A2.83,2.83,0,0,1,44.72,23.75Zm0-.77a2,2,0,0,0,1-.28,2.13,2.13,0,0,0,.72-.74,2.29,2.29,0,0,0,0-2.12,2,2,0,0,0-.72-.74,2,2,0,0,0-1-.27,1.92,1.92,0,0,0-1.72,1A2.37,2.37,0,0,0,43,22a2,2,0,0,0,.71.74A2,2,0,0,0,44.72,23Z"
        />
        <path
          class="cls-4"
          d="M51.54,23.76a2.7,2.7,0,0,1-1.29-.3,2.24,2.24,0,0,1-.9-.88A2.89,2.89,0,0,1,49,21.14V18.53a.41.41,0,0,1,.43-.43.43.43,0,0,1,.31.12.45.45,0,0,1,.12.31v2.61a1.93,1.93,0,0,0,.24,1,1.49,1.49,0,0,0,.63.59,1.92,1.92,0,0,0,.9.2,2.05,2.05,0,0,0,.87-.18,1.59,1.59,0,0,0,.62-.52,1.24,1.24,0,0,0,.22-.74H54a2.35,2.35,0,0,1-.34,1.15,2.31,2.31,0,0,1-.86.81A2.57,2.57,0,0,1,51.54,23.76Zm2.25-.06a.41.41,0,0,1-.31-.12.41.41,0,0,1-.12-.32V18.53a.41.41,0,0,1,.43-.43.44.44,0,0,1,.32.12.41.41,0,0,1,.12.31v4.73a.41.41,0,0,1-.12.32A.44.44,0,0,1,53.79,23.7Z"
        />
        <path
          class="cls-4"
          d="M58.9,23.75a2.75,2.75,0,0,1-1.43-.37,2.87,2.87,0,0,1-1-1,2.82,2.82,0,0,1-.38-1.44V16.14a.44.44,0,0,1,.12-.32.41.41,0,0,1,.32-.12.41.41,0,0,1,.31.12.44.44,0,0,1,.12.32v2.93a2.56,2.56,0,0,1,2.09-1,2.6,2.6,0,0,1,1.39.37,2.72,2.72,0,0,1,1,1,3.06,3.06,0,0,1,.35,1.46,2.86,2.86,0,0,1-.37,1.46,2.77,2.77,0,0,1-1,1A2.81,2.81,0,0,1,58.9,23.75Zm0-.77a2,2,0,0,0,1.75-1,2.29,2.29,0,0,0,0-2.12,1.9,1.9,0,0,0-.72-.74,2,2,0,0,0-1-.27,2,2,0,0,0-1.72,1,2.37,2.37,0,0,0,0,2.12,2,2,0,0,0,.72.74A1.91,1.91,0,0,0,58.9,23Z"
        />
        <path
          class="cls-4"
          d="M66.13,23.75a2.75,2.75,0,0,1-1.43-.37,2.87,2.87,0,0,1-1-1,2.82,2.82,0,0,1-.38-1.44V16.14a.48.48,0,0,1,.11-.32.45.45,0,0,1,.33-.12.41.41,0,0,1,.31.12.44.44,0,0,1,.12.32v2.93a2.56,2.56,0,0,1,2.09-1,2.63,2.63,0,0,1,1.39.37,2.72,2.72,0,0,1,1,1A3.06,3.06,0,0,1,69,20.9a3,3,0,0,1-.37,1.46,2.77,2.77,0,0,1-1,1A2.81,2.81,0,0,1,66.13,23.75Zm0-.77a2,2,0,0,0,1-.28,2,2,0,0,0,.72-.74,2.29,2.29,0,0,0,0-2.12,2,2,0,0,0-2.76-.74,1.94,1.94,0,0,0-.71.74,2.37,2.37,0,0,0,0,2.12,2,2,0,0,0,.71.74A2,2,0,0,0,66.13,23Z"
        />
        <path
          class="cls-4"
          d="M71.79,23.7a1.31,1.31,0,0,1-.76-.23,1.49,1.49,0,0,1-.51-.64,2.16,2.16,0,0,1-.19-.94V16.13a.41.41,0,0,1,.43-.43.39.39,0,0,1,.3.12.41.41,0,0,1,.12.31v5.76a1.31,1.31,0,0,0,.17.69.5.5,0,0,0,.44.27H72a.34.34,0,0,1,.27.12.44.44,0,0,1,.1.3.36.36,0,0,1-.15.31.6.6,0,0,1-.38.12Z"
        />
      </g>
      <g class="cls-3">
        <path
          class="cls-4"
          d="M75.7,23.75a3,3,0,0,1-1.49-.36,2.58,2.58,0,0,1-1-1,2.87,2.87,0,0,1-.37-1.47,3.05,3.05,0,0,1,.35-1.48,2.57,2.57,0,0,1,.95-1,2.65,2.65,0,0,1,1.41-.37,2.62,2.62,0,0,1,1.36.35,2.35,2.35,0,0,1,.89,1,3.12,3.12,0,0,1,.31,1.42.38.38,0,0,1-.11.29.39.39,0,0,1-.29.1H73.42v-.7h4.33l-.44.3a2.23,2.23,0,0,0-.2-1,1.69,1.69,0,0,0-.61-.71,1.76,1.76,0,0,0-1-.25,2,2,0,0,0-1,.26,1.72,1.72,0,0,0-.66.75,2.32,2.32,0,0,0-.23,1.08A2.2,2.2,0,0,0,73.89,22a2,2,0,0,0,.74.74A2.07,2.07,0,0,0,75.7,23a2.25,2.25,0,0,0,.71-.12,1.85,1.85,0,0,0,.58-.3.4.4,0,0,1,.28-.1.34.34,0,0,1,.26.08.45.45,0,0,1,.16.29.33.33,0,0,1-.14.27,2.71,2.71,0,0,1-.86.47A3.06,3.06,0,0,1,75.7,23.75Z"
        />
      </g>
      <path
        class="cls-4"
        d="M79.87,23.7a.44.44,0,0,1-.32-.12.4.4,0,0,1-.12-.31V18.54a.45.45,0,0,1,.12-.32.49.49,0,0,1,.64,0,.5.5,0,0,1,.11.32v4.73a.43.43,0,0,1-.11.31A.44.44,0,0,1,79.87,23.7Zm3.54,0a.41.41,0,0,1-.31-.12.4.4,0,0,1-.12-.31V20.33a1.5,1.5,0,0,0-.37-1.09,1.2,1.2,0,0,0-.92-.39,1.32,1.32,0,0,0-1,.4,1.41,1.41,0,0,0-.39,1h-.59A2.59,2.59,0,0,1,80,19.12a1.92,1.92,0,0,1,.73-.79A2,2,0,0,1,81.81,18a1.92,1.92,0,0,1,1,.28,1.79,1.79,0,0,1,.72.79,2.71,2.71,0,0,1,.26,1.22v2.94a.4.4,0,0,1-.12.31A.41.41,0,0,1,83.41,23.7Zm3.53,0a.44.44,0,0,1-.32-.12.4.4,0,0,1-.12-.31V20.33a1.54,1.54,0,0,0-.36-1.09,1.21,1.21,0,0,0-.93-.39,1.28,1.28,0,0,0-1,.41,1.52,1.52,0,0,0-.38,1.07h-.75a2.57,2.57,0,0,1,.3-1.2,2,2,0,0,1,.79-.8A2.21,2.21,0,0,1,85.33,18a2,2,0,0,1,1.06.28,1.91,1.91,0,0,1,.72.79,2.71,2.71,0,0,1,.26,1.22v2.94a.4.4,0,0,1-.12.31A.43.43,0,0,1,86.94,23.7Z"
      />
      <path
        class="cls-4"
        d="M91.53,23.75a2.64,2.64,0,0,1-1.4-.37,2.72,2.72,0,0,1-1-1,2.91,2.91,0,0,1-.36-1.45,2.87,2.87,0,0,1,.38-1.47,2.77,2.77,0,0,1,1-1,2.84,2.84,0,0,1,3.9,1,3,3,0,0,1,.39,1.47l-.35.17a2.77,2.77,0,0,1-.34,1.36,2.55,2.55,0,0,1-.94,1A2.47,2.47,0,0,1,91.53,23.75Zm.12-.77a2,2,0,0,0,1-.28,2,2,0,0,0,.71-.74,2.27,2.27,0,0,0,0-2.11,2.13,2.13,0,0,0-.71-.75,2.06,2.06,0,0,0-2,0,2.16,2.16,0,0,0-.72.75,2.11,2.11,0,0,0,.72,2.85A2,2,0,0,0,91.65,23Zm2.4.72a.45.45,0,0,1-.32-.12.4.4,0,0,1-.12-.31V21.58l.19-.89.69.22v2.36a.37.37,0,0,1-.13.31A.41.41,0,0,1,94.05,23.7Z"
      />
      <path
        class="cls-4"
        d="M96.46,26.1a.41.41,0,0,1-.44-.44V20.88a2.87,2.87,0,0,1,.39-1.44,2.71,2.71,0,0,1,2.44-1.39,2.86,2.86,0,0,1,1.46.37,2.82,2.82,0,0,1,1,1,2.87,2.87,0,0,1,.38,1.47,2.91,2.91,0,0,1-.36,1.45,2.64,2.64,0,0,1-1,1,2.6,2.6,0,0,1-1.39.37,2.56,2.56,0,0,1-2.09-1v2.93a.44.44,0,0,1-.12.32A.41.41,0,0,1,96.46,26.1ZM98.85,23a2,2,0,0,0,1-.28,1.94,1.94,0,0,0,.71-.74,2.14,2.14,0,0,0-.71-2.86,2,2,0,0,0-1-.27,1.93,1.93,0,0,0-1,.27,2,2,0,0,0-.71.75,2.27,2.27,0,0,0,0,2.11,1.92,1.92,0,0,0,.7.74A2,2,0,0,0,98.85,23Z"
      />
      <path
        class="cls-4"
        d="M105.23,23.75a3.71,3.71,0,0,1-1.24-.21,2.28,2.28,0,0,1-.95-.6.36.36,0,0,1-.11-.3.44.44,0,0,1,.17-.29.43.43,0,0,1,.33-.1.42.42,0,0,1,.28.14,1.56,1.56,0,0,0,.63.43,2.46,2.46,0,0,0,.88.16,1.81,1.81,0,0,0,1-.25.75.75,0,0,0,.34-.59.7.7,0,0,0-.33-.59,2.84,2.84,0,0,0-1.13-.37,3.05,3.05,0,0,1-1.51-.58,1.24,1.24,0,0,1-.48-1,1.27,1.27,0,0,1,.29-.87,1.71,1.71,0,0,1,.75-.53,3,3,0,0,1,1-.17,2.85,2.85,0,0,1,1.18.23,2,2,0,0,1,.81.62.39.39,0,0,1,.1.3.32.32,0,0,1-.17.25.41.41,0,0,1-.31.06.49.49,0,0,1-.3-.16,1.7,1.7,0,0,0-.59-.41,2.09,2.09,0,0,0-.74-.12,1.54,1.54,0,0,0-.86.21.62.62,0,0,0-.33.53.61.61,0,0,0,.12.39.91.91,0,0,0,.42.3,4.85,4.85,0,0,0,.86.23,3.77,3.77,0,0,1,1.21.37,1.63,1.63,0,0,1,.64.56,1.29,1.29,0,0,1,.19.7,1.39,1.39,0,0,1-.27.85,2,2,0,0,1-.76.58A3,3,0,0,1,105.23,23.75Z"
      />
    </g>
    <g id="Search">
      <path
        class="cls-5"
        d="M162.56,18.11h0a5,5,0,0,1,5-5h47a5,5,0,0,1,5,5v6a5,5,0,0,1-5,5h-47a5,5,0,0,1-5-5Z"
      />
      <path
        d="M174.48,24.27l-1.83-1.83a.1.1,0,0,1,0-.15,3.46,3.46,0,0,0,.12-3.36,3.35,3.35,0,0,0-1.82-1.71,3.42,3.42,0,0,0-2.9.09,3.46,3.46,0,0,0-1.73,1.86,3.41,3.41,0,0,0,.11,2.88,3.37,3.37,0,0,0,1.83,1.71,3.47,3.47,0,0,0,3.27-.31c.08-.05.12,0,.18,0l1.87,1.87a.7.7,0,0,0,1,0,.74.74,0,0,0,.24-.43V24.8A1,1,0,0,0,174.48,24.27Zm-4.9-1.39a2.39,2.39,0,1,1,2.4-2.39A2.39,2.39,0,0,1,169.58,22.88Z"
      />
    </g>
    <g id="_2_Logos" data-name="2 Logos">
      <path
        class="cls-6"
        d="M130.85,23c0-.35,0-.7,0-1.05a.37.37,0,0,0-.22-.36l-1.86-1.06a.33.33,0,0,1-.2-.34c0-.36,0-.72,0-1.08a.3.3,0,0,1,.18-.32c.34-.18.67-.37,1-.58a.24.24,0,0,1,.3,0c.59.35,1.19.68,1.77,1a.53.53,0,0,0,.62,0c.56-.35,1.15-.66,1.72-1a.32.32,0,0,1,.39,0,3.81,3.81,0,0,0,.56.31,1,1,0,0,1,.62,1.07c0,.53-.06.91-.64,1.14a10.68,10.68,0,0,0-1.43.81.38.38,0,0,0-.23.39c0,.68,0,1.35,0,2a.41.41,0,0,1-.26.43l-.92.53a.26.26,0,0,1-.29,0c-.32-.2-.64-.39-1-.57a.3.3,0,0,1-.16-.3C130.86,23.76,130.85,23.4,130.85,23Z"
      />
      <path
        class="cls-6"
        d="M143.61,20.89c1.38-2.24,2.75-4.49,4.14-6.73,0-.05,0-.23.17-.11a1.89,1.89,0,0,1,.08.75v3.74A1,1,0,0,1,148,19a.69.69,0,0,1-.36.27c-1.15.5-2.29,1-3.43,1.53C144,20.86,143.82,21,143.61,20.89Z"
      />
      <path
        class="cls-7"
        d="M148,14.17a15.22,15.22,0,0,1,.94,1.47c.7,1.12,1.38,2.24,2.06,3.36l1.13,1.83c-.07.21-.21.07-.29,0-.47-.18-.92-.4-1.37-.6-.73-.34-1.46-.65-2.19-1-.15-.07-.35-.1-.31-.35V14.5A.62.62,0,0,1,148,14.17Z"
      />
      <path
        class="cls-7"
        d="M143.61,20.89a3.61,3.61,0,0,0,.94-.37l3.3-1.48,0,0a.66.66,0,0,1,.05.34c0,1.2,0,2.41,0,3.62,0,.14.06.32-.15.38l-2.34-1.34-1.76-1C143.63,21,143.51,21,143.61,20.89Z"
      />
      <path
        class="cls-8"
        d="M148,19.05c.54.3,1.12.52,1.68.77l2.23,1c.07,0,.14.11.22,0,.18.19-.07.23-.13.27-.39.25-.8.47-1.19.7l-2.71,1.56a1,1,0,0,1-.16.06c0-.06,0-.14,0-.21,0-1.37,0-2.75,0-4.12C147.9,19,147.93,19,148,19.05Z"
      />
      <path
        class="cls-6"
        d="M126.36,20.2l1.28.73c.11.07.08.2.08.31,0,.69,0,1.39,0,2.09a.3.3,0,0,0,.18.31c.61.34,1.21.71,1.82,1.06.14.08.23.15.22.32v1.31a2.34,2.34,0,0,1-.69-.34l-2.61-1.52a.43.43,0,0,1-.27-.45C126.37,22.77,126.36,21.52,126.36,20.2Z"
      />
      <path
        class="cls-6"
        d="M135.69,16.39l-1.22.69c-.13.08-.22,0-.31,0-.59-.34-1.17-.67-1.75-1a.44.44,0,0,0-.51,0c-.61.36-1.23.7-1.83,1.06a.27.27,0,0,1-.33,0,10.19,10.19,0,0,0-.91-.52c-.15-.06-.26-.15,0-.29l3.12-1.82a.38.38,0,0,1,.43,0l2.94,1.7Z"
      />
      <path
        class="cls-6"
        d="M137.89,22.31c0,.6,0,1.19,0,1.79a.32.32,0,0,1-.17.32l-3,1.72-.22.12c-.12.07-.17,0-.17-.1,0-.41,0-.82,0-1.23a.23.23,0,0,1,.14-.22c.59-.33,1.18-.69,1.78-1a.51.51,0,0,0,.3-.53c0-.64,0-1.27,0-1.91a.46.46,0,0,1,.27-.46,6,6,0,0,0,.74-.43c.23-.16.3-.12.3.16C137.89,21.12,137.89,21.72,137.89,22.31Z"
      />
      <path
        class="cls-6"
        d="M147.92,27.68c-.09.09-.11,0-.15-.05l-3-4.17-1.14-1.56a1.08,1.08,0,0,1,.61.27c1.09.62,2.17,1.26,3.26,1.89a.8.8,0,0,0,.42.16.55.55,0,0,1,.09.41c0,.83,0,1.65,0,2.48A1.8,1.8,0,0,1,147.92,27.68Z"
      />
      <path
        class="cls-7"
        d="M147.92,27.68c-.1-.17,0-.34,0-.51,0-1,0-2,0-3l2.34-1.34c.57-.32,1.12-.66,1.68-1,.05,0,.1,0,.18,0l-1,1.4c-.78,1.07-1.55,2.14-2.33,3.2A9.75,9.75,0,0,1,147.92,27.68Z"
      />
      <path
        class="cls-6"
        d="M126.36,18.4c-.23-.72.29-.91.76-1.22a.88.88,0,0,1,1.18.06,2.91,2.91,0,0,0,.45.26c.08,0,.17.08.17.16s-.1.12-.17.15a8.44,8.44,0,0,1-.83.47.36.36,0,0,0-.21.39c0,.35,0,.7,0,1,0,.18-.08.2-.2.12l-1.06-.61a.26.26,0,0,1-.1-.25Z"
      />
      <path
        class="cls-6"
        d="M136.58,19.92c0-.48,0-.89,0-1.3,0-.17-.06-.24-.2-.32a6.52,6.52,0,0,1-1.08-.66,14.34,14.34,0,0,1,1.24-.73c.07,0,.11,0,.16.05.2.08.37.24.56.31.58.19.63.61.65,1.14s-.12,1-.69,1.16A4.19,4.19,0,0,0,136.58,19.92Z"
      />
      <path
        class="cls-6"
        d="M130.85,26.13V26c0-.18-.1-.45.07-.54s.32.17.5.25a.31.31,0,0,1,.15.1c.42.4.82.25,1.2,0,.14-.12.32-.17.47-.28s.21,0,.21.13c0,.34,0,.68,0,1a.45.45,0,0,1-.26.44c-.3.16-.59.33-.88.5a.29.29,0,0,1-.32,0,3.62,3.62,0,0,0-.43-.24C131,27.1,130.67,26.77,130.85,26.13Z"
      />
      <path
        class="cls-6"
        d="M134.36,23.8c0-.53,0-1,0-1.41a.23.23,0,0,1,.11-.23c.34-.18.68-.37,1-.57s.25,0,.24.16c0,.36,0,.72,0,1.07,0,.1,0,.19-.1.24Z"
      />
      <path
        class="cls-6"
        d="M129.93,23v.28c0,.52,0,.52-.45.27a3.5,3.5,0,0,0-.42-.24.7.7,0,0,1-.49-.85,4.56,4.56,0,0,0,0-.74c0-.15,0-.25.19-.13s.61.33.91.51a.41.41,0,0,1,.26.44A2.36,2.36,0,0,0,129.93,23Z"
      />
      <path
        class="cls-6"
        d="M133.47,17.66c-.46.26-.86.49-1.25.73-.08,0-.14,0-.2,0l-1-.57c-.1-.07-.18-.14,0-.24.34-.18.67-.39,1-.59a.21.21,0,0,1,.25,0Z"
      />
      <path
        class="cls-9"
        d="M148,19.05h-.06s0,0,0,0h0a.66.66,0,0,0,.06-.39c0-1.51,0-3,0-4.55a.08.08,0,0,1,0,0c.06,0,.06.07.06.12Z"
      />
      <path
        class="cls-10"
        d="M147.87,19.05h0v4.37c0,.08-.09.07-.13,0,.12-.1.06-.23.07-.35C147.86,21.73,147.86,20.39,147.87,19.05Z"
      />
    </g>
    <g id="Bubbles">
      <path
        class="cls-11"
        d="M93.32,110.59h0a19.35,19.35,0,0,1,19.35-19.36h0A19.36,19.36,0,0,1,132,110.59h0a19.35,19.35,0,0,1-19.36,19.35h0A19.35,19.35,0,0,1,93.32,110.59Z"
      />
      <path
        class="cls-12"
        d="M126.85,138.91h0a11.65,11.65,0,0,1,11.65-11.66h0a11.66,11.66,0,0,1,11.65,11.66h0a11.66,11.66,0,0,1-11.65,11.65h0A11.65,11.65,0,0,1,126.85,138.91Z"
      />
      <path
        class="cls-13"
        d="M52.37,87.59h0A10.34,10.34,0,0,1,62.71,77.24h0A10.35,10.35,0,0,1,73.06,87.59h0A10.34,10.34,0,0,1,62.71,97.93h0A10.34,10.34,0,0,1,52.37,87.59Z"
      />
      <path
        class="cls-13"
        d="M81.93,145.37h0a8,8,0,0,1,8-8h0a8,8,0,0,1,8,8h0a8,8,0,0,1-8,8h0A8,8,0,0,1,81.93,145.37Z"
      />
      <path
        class="cls-13"
        d="M109.54,171.2h0a6.71,6.71,0,0,1,6.71-6.71h0A6.73,6.73,0,0,1,123,171.2h0a6.71,6.71,0,0,1-6.71,6.71h0A6.7,6.7,0,0,1,109.54,171.2Z"
      />
      <path
        class="cls-13"
        d="M50.44,174.32h0a4.9,4.9,0,0,1,4.9-4.9h0a4.9,4.9,0,0,1,4.9,4.9h0a4.9,4.9,0,0,1-4.9,4.89h0A4.89,4.89,0,0,1,50.44,174.32Z"
      />
      <path
        class="cls-13"
        d="M166.32,100.44h0a5,5,0,0,1,5-5h0a5,5,0,0,1,5,5h0a5,5,0,0,1-5,5h0A5,5,0,0,1,166.32,100.44Z"
      />
      <path
        class="cls-13"
        d="M210.27,133.88h0a3.69,3.69,0,0,1,3.7-3.7h0a3.69,3.69,0,0,1,3.7,3.7h0a3.7,3.7,0,0,1-3.7,3.7h0A3.7,3.7,0,0,1,210.27,133.88Z"
      />
      <path
        class="cls-13"
        d="M177.77,145.18h0a3.7,3.7,0,0,1,3.7-3.7h0a3.69,3.69,0,0,1,3.7,3.7h0a3.7,3.7,0,0,1-3.7,3.7h0A3.7,3.7,0,0,1,177.77,145.18Z"
      />
      <path
        class="cls-13"
        d="M82,62.48h0A10.34,10.34,0,0,1,92.29,52.14h0a10.35,10.35,0,0,1,10.35,10.34h0A10.35,10.35,0,0,1,92.29,72.83h0A10.34,10.34,0,0,1,82,62.48Z"
      />
      <path
        class="cls-13"
        d="M156.65,171.9h0a6.27,6.27,0,0,1,6.27-6.27h0a6.28,6.28,0,0,1,6.27,6.27h0a6.27,6.27,0,0,1-6.27,6.27h0A6.27,6.27,0,0,1,156.65,171.9Z"
      />
      <path
        class="cls-13"
        d="M78.19,168.64h0a6.72,6.72,0,0,1,6.71-6.71h0a6.73,6.73,0,0,1,6.71,6.71h0a6.7,6.7,0,0,1-6.71,6.71h0A6.71,6.71,0,0,1,78.19,168.64Z"
      />
      <path
        class="cls-13"
        d="M206.12,100.43h0a3,3,0,0,1,3-3.05h0a3.06,3.06,0,0,1,3.06,3.05h0a3.06,3.06,0,0,1-3.06,3.06h0A3.05,3.05,0,0,1,206.12,100.43Z"
      />
      <path
        class="cls-13"
        d="M192.82,163h0a5,5,0,0,1,5-5h0a5.05,5.05,0,0,1,5,5h0a5,5,0,0,1-5,5h0A5,5,0,0,1,192.82,163Z"
      />
      <path
        class="cls-13"
        d="M154,122.78h0a10.35,10.35,0,0,1,10.35-10.35h0a10.36,10.36,0,0,1,10.35,10.35h0a10.36,10.36,0,0,1-10.35,10.35h0A10.35,10.35,0,0,1,154,122.78Z"
      />
      <path
        class="cls-13"
        d="M50.58,128.22h0a5,5,0,0,1,5-5h0a5,5,0,0,1,5,5h0a5,5,0,0,1-5,5h0A5,5,0,0,1,50.58,128.22Z"
      />
      <path
        class="cls-13"
        d="M51.67,58.75h0a3.7,3.7,0,0,1,3.7-3.7h0a3.72,3.72,0,0,1,3.7,3.7h0a3.71,3.71,0,0,1-3.7,3.71h0A3.7,3.7,0,0,1,51.67,58.75Z"
      />
      <path
        class="cls-14"
        d="M126.44,80.05h0a14.41,14.41,0,0,1,14.41-14.41h0a14.41,14.41,0,0,1,14.41,14.41h0a14.41,14.41,0,0,1-14.41,14.41h0A14.41,14.41,0,0,1,126.44,80.05Z"
      />
      <path
        class="cls-13"
        d="M133.69,53.19h0a5,5,0,0,1,5-5h0a5,5,0,0,1,5,5h0a5,5,0,0,1-5,5h0A5,5,0,0,1,133.69,53.19Z"
      />
      <path
        class="cls-13"
        d="M40.18,148.39h0a3.7,3.7,0,0,1,3.7-3.7h0a3.72,3.72,0,0,1,3.7,3.7h0a3.7,3.7,0,0,1-3.7,3.7h0A3.69,3.69,0,0,1,40.18,148.39Z"
      />
      <path
        class="cls-13"
        d="M193.63,80h0a3.7,3.7,0,0,1,3.7-3.7h0A3.69,3.69,0,0,1,201,80h0a3.7,3.7,0,0,1-3.7,3.7h0A3.7,3.7,0,0,1,193.63,80Z"
      />
    </g>
  </svg>
</template>

<script>
export default { name: "BubblemapsScreen" };
</script>

<style scoped lang="scss">
.cls-1 {
  fill: url(#radial-gradient);
}
.cls-2 {
  fill: #d0d0d0;
}
.cls-3 {
  isolation: isolate;
}
.cls-4 {
  fill: #fff;
}
.cls-5 {
  fill: #d9d9d9;
}
.cls-11,
.cls-12,
.cls-13,
.cls-14,
.cls-5 {
  fill-rule: evenodd;
}
.cls-6 {
  fill: #c1c1c1;
}
.cls-7 {
  fill: #9c9c9c;
}
.cls-8 {
  fill: #757575;
}
.cls-9 {
  fill: #999;
}
.cls-10 {
  fill: #7c7c7c;
}
.cls-11 {
  fill: #de219a;
}
.cls-12 {
  fill: #8d26d8;
}
.cls-13 {
  fill: #441663;
}
.cls-14 {
  fill: #1581dd;
}
</style>
