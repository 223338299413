<template>
  <Tweet
    tweet_link="https://twitter.com/OlympusDAO/status/1546509710211391489"
    image_name="olympus.jpeg"
    name="OlympusDAO"
    handle="@OlympusDAO"
  >
    Check out <b>@bubblemaps</b> who create beautiful mapped visual connections
    of the top holders from various DeFi projects.
    <br />
    No more snooping on etherscan&nbsp;👀
  </Tweet>
</template>

<script>
import Tweet from "@/components/atoms/Tweet.vue";

export default {
  components: {
    Tweet,
  },
};
</script>
