<template>
  <Tweet
    tweet_link="https://twitter.com/cronos_chain/status/1575306130506469376"
    image_name="cronos.png"
    name="Cronos"
    handle="@cronos_chain"
    is_verified
    is_gold
  >
    Welcome <b>@bubblemaps</b> to Cronos!&nbsp;🎉 <br />Bubblemaps provides
    innovative and beautiful bubble visualisations for any user to analyse
    blockchain data.
  </Tweet>
</template>

<script>
import Tweet from "@/components/atoms/Tweet.vue";

export default {
  components: {
    Tweet,
  },
};
</script>
