<template>
  <Tweet
    tweet_link="https://twitter.com/FantomFDN/status/1559978124540088320"
    image_name="fantom.png"
    name="Fantom Foundation"
    handle="@FantomFDN"
    is_verified
    is_gold
  >
    Very excited to welcome <b>@bubblemaps</b> to the #Fantom ecosystem, an
    innovative visual platform for breaking down blockchain data&nbsp;🎊
  </Tweet>
</template>

<script>
import Tweet from "@/components/atoms/Tweet.vue";

export default {
  components: {
    Tweet,
  },
};
</script>
