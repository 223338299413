<template>
  <svg viewBox="-21 -117 682.66672 682" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M626.8125 64.035156c-7.375-27.417968-28.992188-49.03125-56.40625-56.414062C520.324219-6.082031 319.992188-6.082031 319.992188-6.082031s-200.324219 0-250.40625 13.183593c-26.886719 7.375-49.03125 29.519532-56.40625 56.933594C0 114.113281 0 217.96875 0 217.96875s0 104.378906 13.179688 153.933594c7.382812 27.414062 28.992187 49.027344 56.410156 56.410156C120.195312 442.019531 320 442.019531 320 442.019531s200.324219 0 250.40625-13.183593c27.417969-7.378907 49.03125-28.992188 56.414062-56.40625 13.175782-50.082032 13.175782-153.933594 13.175782-153.933594s.527344-104.382813-13.183594-154.460938zM256.210938 313.914062V122.023438l166.585937 95.945312zm0 0"
    />
  </svg>
</template>

<script>
export default { name: "YoutubeLogo" };
</script>
