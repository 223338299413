<template>
  <svg viewBox="0 0 48 48">
    <path
      d="M36.15 26.4H7.5q-1 0-1.675-.675T5.15 24.05q0-1 .675-1.675T7.5 21.7h28.7l-3.4-3.4q-.65-.7-.675-1.625-.025-.925.675-1.625.75-.7 1.725-.7.975 0 1.675.7l7.35 7.35q.35.35.525.775.175.425.175.875t-.175.875q-.175.425-.525.775l-7.35 7.35q-.75.75-1.675.75t-1.675-.75q-.7-.65-.7-1.65 0-1 .7-1.7Z"
    />
  </svg>
</template>

<script>
export default { name: "RightArrowIcon" };
</script>
