<template>
  <svg viewBox="0 0 48 48">
    <path
      d="M24 21.45q-8.25 0-13.4-2.65t-5.15-5.95q0-3.35 5.125-5.975Q15.7 4.25 24 4.25q8.25 0 13.4 2.625t5.15 5.975q0 3.3-5.125 5.95Q32.3 21.45 24 21.45Zm0 11.1q-7.5 0-13.025-2.35T5.45 24.5v-6.15q0 1.8 1.575 3.275 1.575 1.475 4.2 2.55 2.625 1.075 5.95 1.675 3.325.6 6.825.6t6.825-.575q3.325-.575 5.95-1.65t4.2-2.575q1.575-1.5 1.575-3.3v6.15q0 3.35-5.5 5.7T24 32.55Zm0 11.15q-7.5 0-13.025-2.35t-5.525-5.7V29.5q0 1.8 1.575 3.275 1.575 1.475 4.2 2.55 2.625 1.075 5.95 1.65T24 37.55q3.5 0 6.825-.575t5.95-1.625q2.625-1.05 4.2-2.525Q42.55 31.35 42.55 29.5v6.15q0 3.35-5.5 5.7T24 43.7Z"
    />
  </svg>
</template>

<script>
export default { name: "DataIcon" };
</script>
