<template>
  <Tweet
    tweet_link="https://twitter.com/ape_swap/status/1445121846253441027"
    image_name="apeswap.png"
    name="ApeSwap"
    handle="@ape_swap"
    is_verified
  >
    Explore transactions like never before with <b>@bubblemaps</b>&nbsp;💡
  </Tweet>
</template>

<script>
import Tweet from "@/components/atoms/Tweet.vue";

export default {
  components: {
    Tweet,
  },
};
</script>
