<template>
  <div
    :class="'mdc-dialog' + (defaultWidth ? ' mdc-dialog--custom-width' : '')"
  >
    <div class="mdc-dialog__container">
      <div
        class="mdc-dialog__surface"
        role="alertdialog"
        aria-modal="true"
        aria-labelledby="my-dialog-title"
        aria-describedby="my-dialog-content"
        :style="defaultWidth ? `width:${defaultWidth}px;` : ''"
      >
        <slot></slot>
      </div>
    </div>
    <div class="mdc-dialog__scrim"></div>
  </div>
</template>

<script>
import { MDCDialog } from "@material/dialog";

export default {
  name: "Dialog",
  props: {
    defaultWidth: Number,
  },
  data() {
    return {
      mdcComponent: undefined,
    };
  },
  mounted: function () {
    this.mdcComponent = new MDCDialog(this.$el);
    this.mdcComponent.listen("MDCDialog:closing", () => {
      this.$emit("closing");
    });
    this.mdcComponent.listen("MDCDialog:opened", () => {
      this.$emit("opened");
    });
  },
  unmounted: function () {
    this.mdcComponent.destroy();
  },
  methods: {
    open() {
      this.mdcComponent.open();
    },
    close() {
      this.mdcComponent.close();
    },
  },
};
</script>

<style lang="scss">
@use "@material/dialog";
@import "src/assets/variables";

.mdc-dialog {
  a {
    color: $theme-blue;
    &:hover {
      text-decoration: underline;
    }
    &:focus-visible {
      outline: none;
      background-color: rgba(white, 0.15);
      border-radius: 4px;
    }
  }
  .mdc-dialog__content {
    color: rgba(255, 255, 255, 0.7);
    font-size: 1em;
    line-height: 1.5em;
  }
  .mdc-dialog__surface {
    border-radius: 15px;
    background: url("../../../assets/backgrounds/background4.svg") no-repeat
      center;
    background-size: cover;
  }
  .mdc-dialog__scrim {
    background-color: rgba(0, 0, 0, 0.8);
  }
}
.mdc-dialog__actions {
  min-height: initial;
}

.mdc-dialog--custom-width {
  // When using custom width we just set max-width really high so that it
  // doesn't interfere.
  @include dialog.max-width(3000px, 16px);
}
</style>
