<template>
  <div class="partners">
    <h2>Partners</h2>
    <h4>
      Bubblemaps empowers multiple platforms and ecosystems: NFT marketplaces,
      analytical tools, blockchains, and more.
    </h4>
    <Button raised class="partners__button" route="/work-with-us">
      Work with us
    </Button>
    <div class="partners__images noselect">
      <div class="partner --1">
        <img alt="Nftkey logo" src="@/assets/logos/nftkey.png" />
      </div>
      <div class="partner --2">
        <img alt="Solana logo" src="@/assets/logos/solana.jpeg" />
      </div>
      <div class="partner --9">
        <img alt="Opensea Pro logo" src="@/assets/logos/openseapro.png" />
      </div>
      <div class="partner --5">
        <img alt="DEX Screener logo" src="@/assets/logos/dexscreener.png" />
      </div>
      <div class="partner --4">
        <img alt="Fantom logo" src="@/assets/logos/fantom.png" />
      </div>
      <div class="partner --8">
        <img alt="BNB Chain logo" src="@/assets/logos/bnbchain.png" />
      </div>
      <div class="partner --3">
        <img alt="Base logo" src="@/assets/logos/base.jpg" />
      </div>
      <div class="partner --7">
        <img alt="RugDoc logo" src="@/assets/logos/rugdoc.jpeg" />
      </div>
      <div class="partner --6">
        <img alt="Avalanche logo" src="@/assets/logos/avalanche.jpeg" />
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/atoms/material/Button.vue";

export default {
  name: "Partners",
  components: {
    Button,
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/variables";

.partners {
  text-align: center;

  .partners__button {
    margin-top: 20px;
  }

  .partners__images {
    width: 100%;
    max-width: 1000px;
    height: 230px;
    position: relative;
    margin: auto;
    margin-top: 30px;
    margin-bottom: -30px;

    @media (min-width: 520px) {
      height: 270px;
    }

    @media (min-width: 700px) {
      height: 320px;
      margin-bottom: -15px;
    }

    @media (min-width: 850px) {
      height: 400px;
    }

    @media (min-width: 1000px) {
      height: 460px;
      margin-top: 40px;
    }
  }

  .partner {
    position: absolute;
    animation: float 1.5s infinite alternate ease-in-out;

    img {
      border-radius: 15%;
      transform: scale(0.5);

      @media (min-width: 520px) {
        transform: scale(0.6);
      }

      @media (min-width: 700px) {
        transform: scale(0.7);
      }

      @media (min-width: 850px) {
        transform: scale(0.8);
      }

      @media (min-width: 1000px) {
        transform: scale(1);
      }
    }

    &.--1 {
      top: 0;
      left: 0;
      filter: blur(4px);
      animation-delay: 0.5s;

      img {
        height: 200px;
        width: 200px;
        transform-origin: top left;
      }
    }

    &.--2 {
      top: 0;
      right: 0;
      filter: blur(0px);

      img {
        height: 150px;
        width: 150px;
        transform-origin: top right;
      }
    }

    &.--3 {
      top: 65%;
      left: 15%;
      filter: blur(1px);
      animation-delay: 1s;

      img {
        height: 120px;
        width: 120px;
        transform-origin: top left;
      }
    }

    &.--4 {
      top: 65%;
      right: 15%;
      filter: blur(1px);

      img {
        height: 140px;
        width: 140px;
        transform-origin: top right;
      }
    }

    &.--5 {
      top: 54%;
      right: 10%;
      filter: blur(4px);
      animation-delay: 0.7s;

      img {
        height: 100px;
        width: 100px;
        transform-origin: top right;
      }
    }

    &.--6 {
      top: 32%;
      left: 40%;
      filter: blur(0px);
      animation-delay: 0.5s;

      img {
        height: 150px;
        width: 150px;
        transform-origin: top left;
      }
    }

    &.--7 {
      top: 54%;
      left: 32%;
      filter: blur(4px);

      img {
        height: 120px;
        width: 120px;
        transform-origin: top left;
      }
    }

    &.--8 {
      top: 15%;
      left: 18%;
      filter: blur(1px);
      animation-delay: 0.8s;

      img {
        height: 100px;
        width: 100px;
        transform-origin: top left;
      }
    }

    &.--9 {
      top: 10%;
      right: 25%;
      filter: blur(1px);
      animation-delay: 1.2s;

      img {
        height: 120px;
        width: 120px;
        transform-origin: top right;
      }
    }
  }

  @keyframes float {
    0% {
      transform: translateY(2px);
    }
    100% {
      transform: translateY(-2px);
    }
  }
}
</style>
