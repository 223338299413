<template>
  <div class="header">
    <div class="header__text-container">
      <div>
        <h1>
          Innovative Visuals for<br />
          Blockchain Data
        </h1>
        <h3>Transparent, Effective, Playful</h3>
      </div>
      <div>
        <Button outlined class="header__button" route="/get-premium">
          Get Premium
        </Button>
        <Button
          raised
          class="header__button"
          link="https://app.bubblemaps.io"
          target="_blank"
        >
          Launch App
        </Button>
      </div>
    </div>
    <div class="header__screens">
      <div
        v-scrollreveal="{ delay: 0, duration: 800 }"
        class="sr_prevent_flickering --1 noselect"
      >
        <BlockchainScreen />
      </div>
      <div
        v-scrollreveal="{ delay: 0, duration: 1200 }"
        class="sr_prevent_flickering --2"
      >
        <EtherscanScreen />
      </div>
      <div
        v-scrollreveal="{ delay: 0, duration: 1800 }"
        class="sr_prevent_flickering --3"
      >
        <div class="header__screen-glow"><BubblemapsScreen /></div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/atoms/material/Button.vue";
import BlockchainScreen from "./parts/Blockchain.vue";
import EtherscanScreen from "./parts/Etherscan.vue";
import BubblemapsScreen from "./parts/Bubblemaps.vue";

export default {
  name: "Header",
  components: {
    Button,
    BlockchainScreen,
    EtherscanScreen,
    BubblemapsScreen,
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/variables";

.header {
  text-align: center;
  min-height: calc(100vh - 56px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .header__text-container {
    height: 350px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1 {
      margin-top: 0;
      margin-bottom: 1rem;
      @include scaling-title(2.5rem, 540px, 400px);
    }

    h3 {
      font-size: 1.3rem;
      font-weight: normal;
      opacity: 0.7;
      margin-top: 0;
      margin-bottom: 2rem;
    }

    .header__button {
      margin: 5px;
    }
  }

  @media (max-width: $mdc-tablet) {
    .header__text-container {
      height: 300px;
    }
  }
}

.header__screens {
  position: relative;
  margin: auto;
  width: 100%;
  max-width: 560px;
  height: calc(100vw * 0.8);
  max-height: 430px;
  margin: auto;

  > div {
    position: absolute;

    &.--1 {
      width: 45%;
      top: 0;
      left: 0;
    }

    &.--2 {
      width: 55%;
      top: 10%;
      left: 10%;
    }

    &.--3 {
      width: 70%;
      top: 20%;
      left: 30%;
    }
  }
}

.header__screen-glow {
  animation: pulsing-glow 3s infinite ease-in-out;
  border-radius: 10% / 12%;
  animation-delay: 0.5s;
  svg {
    margin-bottom: -5px;
  }
}

@media (min-width: 550px) {
  .header {
    .header__screens {
      margin-bottom: 40px;
    }
  }
}

@media (min-width: $mdc-tablet) {
  .header {
    min-height: calc(100vh - 64px);
  }

  @media (min-height: 1000px) {
    .header {
      min-height: calc(1000px - 64px);
    }
  }
}

@media (min-width: 1230px) {
  .header {
    flex-direction: row;

    .header__screens {
      margin: auto;
    }

    .header__text-container {
      text-align: left;
    }

    button {
      margin-left: 0 !important;
      margin-right: 10px !important;
    }
  }
}

@media (min-width: 1400px) {
  .header {
    .header__text-container {
      h1 {
        font-size: 3rem;
      }
    }
  }
}

@keyframes pulsing-glow {
  0% {
    box-shadow: 0px 0px 50px 0px #ffede63b;
    transform: translateY(2px);
  }
  50% {
    transform: translateY(-2px);
    box-shadow: 0px 0px 80px 15px #ffede63b;
  }
  100% {
    box-shadow: 0px 0px 50px 0px #ffede63b;
    transform: translateY(2px);
  }
}
</style>
