<template>
  <div class="appintro">
    <h2>What is Bubblemaps?</h2>
    <h4>
      Bubblemaps is the first <b>supply auditing tool</b> for DeFi tokens and
      NFTs. Our unique and colorful bubbles make on-chain data easy to
      understand.<br /><br />
      Investigate wallets, reveal connections, and see through the noise of
      blockchain data.
    </h4>
    <Button outlined class="appintro__button" route="/case-studies">
      Case Studies
    </Button>
    <div class="appintro__container" ref="lottiecontainer">
      <lottie-player
        :src="require(`@/assets/lottie/tutorial.json`)"
        background="transparent"
        speed="1"
        class="lottie-player"
        loop
        :autoplay="autoplay"
        ref="lottieplayer"
      ></lottie-player>
      <div class="appintro__controls">
        <div
          :key="section"
          v-for="(section, index) in sections"
          @click="go_to_animation(index)"
          :class="
            'appintro__control' + (index == currentSection ? ' --active' : '')
          "
        >
          {{ section["name"] }}
          <div
            v-if="index == currentSection"
            class="progress-bar"
            :style="{ width: `${currentProgress}%` }"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@lottiefiles/lottie-player";
import Button from "@/components/atoms/material/Button.vue";

export default {
  name: "AppIntro",
  components: { Button },
  data: function () {
    return {
      sections: [
        { name: "Explore Wallets", frame: 0 },
        { name: "Reveal Connections", frame: 450 },
        { name: "Manage your Map", frame: 1030 },
        { name: "Travel in Time", frame: 1510 },
      ],
      lastFrame: 1838,
      currentSection: 0,
      currentProgress: 0,
      totalFrames: null,
    };
  },
  methods: {
    go_to_animation(i) {
      let frameTarget = this.sections[i]["frame"];
      this.currentSection = i;
      this.$refs.lottieplayer.seek(frameTarget);
    },
    onFrame() {
      const currentFrame = this.$refs.lottieplayer.getLottie().currentFrame;
      // Update current section if needed
      for (let i = this.sections.length - 1; i >= 0; i--) {
        if (currentFrame >= this.sections[i]["frame"]) {
          this.currentSection = i;
          break;
        }
      }
      // Compute section progress
      const frameStart = this.sections[this.currentSection]["frame"];
      const frameEnd =
        this.currentSection == this.sections.length - 1
          ? this.lastFrame
          : this.sections[this.currentSection + 1]["frame"];
      this.currentProgress =
        ((currentFrame - frameStart) * 100) / (frameEnd - frameStart);
    },
  },
  mounted: function () {
    this.$refs.lottieplayer.addEventListener("frame", () => {
      this.onFrame();
    });
    // Play/Pause animation on scroll
    // (for browsers supporting IntersectionObserver)
    let that = this;
    var observer = new IntersectionObserver(function (entries) {
      if (entries[0].isIntersecting) {
        that.$refs.lottieplayer.play();
      } else {
        that.$refs.lottieplayer.pause();
      }
    });
    observer.observe(this.$refs.lottiecontainer);
  },
  beforeUnmount: function () {
    this.$refs.lottieplayer.removeEventListener("frame", () => {
      this.onFrame();
    });
  },
  computed: {
    autoplay() {
      return (
        !("IntersectionObserver" in window) ||
        !("IntersectionObserverEntry" in window) ||
        !("intersectionRatio" in window.IntersectionObserverEntry.prototype) ||
        !("isIntersecting" in window.IntersectionObserverEntry.prototype)
      );
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/variables";

.appintro {
  text-align: center;

  .appintro__button {
    margin-top: 20px;
  }

  .appintro__container {
    margin: 50px 0 70px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .lottie-player {
    width: 800px;
    border-radius: 15px;
    border: 1px solid rgba(white, 0.1);
  }

  .appintro__controls {
    background-color: rgba(white, 0.1);
    border-radius: 15px;
    padding: 15px;
    margin: 0 0 0 20px;
  }

  .appintro__control {
    cursor: pointer;
    font-weight: 700;
    font-size: 1.2rem;
    margin: 5px;
    padding: 5px 10px;
    border-radius: 5px;
    transition: background-color 500ms cubic-bezier(0, 0, 0.1, 1);
    white-space: nowrap;
    position: relative;
    overflow: hidden;

    @media (hover: hover) {
      &:hover:not(.--active) {
        color: $theme-blue;
      }
    }

    &.--active {
      background-color: rgba($theme-blue, 0.8);
    }

    .progress-bar {
      background-color: white;
      height: 2px;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}

@media (max-width: calc($mdc-desktop - 1px)) {
  .appintro {
    .appintro__control {
      font-size: 1rem;
      margin: 3px;
      padding: 3px 6px;

      .progress-bar {
        height: 1px;
      }
    }

    .appintro__controls {
      padding: 10px;
      margin: 0 0 0 15px;
    }
  }
}

@media (max-width: 700px) {
  .appintro {
    .appintro__container {
      flex-direction: column;
    }

    .appintro__controls {
      order: 0;
      margin: 0 0 20px 0;
    }

    .lottie-player {
      order: 1;
      width: 100%;
    }
  }
}
</style>
