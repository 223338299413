<template>
  <Tweet
    tweet_link="https://twitter.com/Reef_Chain/status/1447627401631092739"
    image_name="reef.jpeg"
    name="Reef"
    handle="@Reef_Chain"
    is_verified
  >
    Reef Finance is delighted to share that $REEF is now visible on
    <b>@bubblemaps</b>! This analytical tool helps illuminate token and wallet
    transaction intricacies that are otherwise hard to discern.
  </Tweet>
</template>

<script>
import Tweet from "@/components/atoms/Tweet.vue";

export default {
  components: {
    Tweet,
  },
};
</script>
