<template>
  <Tweet
    tweet_link="https://twitter.com/BNBCHAIN/status/1611017146745643010"
    image_name="bnbchain.png"
    name="BNB Chain"
    handle="@BNBCHAIN"
    is_verified
  >
    Join us in welcoming
    <b>@bubblemaps</b>
    to AvengerDAO as we continue our endeavour to protect users
  </Tweet>
</template>

<script>
import Tweet from "@/components/atoms/Tweet.vue";

export default {
  components: {
    Tweet,
  },
};
</script>
