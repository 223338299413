<template>
  <div class="header">
    <div class="mdc-layout-grid">
      <div class="mdc-layout-grid__inner">
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12 title">
          <div v-scrollreveal class="sr_prevent_flickering">
            <h1>The most Advanced Data<br />Visualisation Suite</h1>
            <h4>
              From DeFi tokens to VCs or marketing agencies, Bubblemaps&nbsp;Pro
              meets&nbsp;your&nbsp;needs.
            </h4>
          </div>
          <Button
            raised
            v-scrollreveal="{ duration: 2000 }"
            class="sr_prevent_flickering"
            link="mailto:contact@bubblemaps.io"
            >Contact Us</Button
          >
        </div>
      </div>
    </div>
  </div>
  <div class="workwithus">
    <div class="mdc-layout-grid">
      <div class="mdc-layout-grid__inner workwithus__features">
        <div
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6 feature"
        >
          <a
            target="_blank"
            href="https://docs.google.com/forms/d/e/1FAIpQLSdj9s9DhslOWlgv1xaJRlOAAv-3lYOzR7W9IWpnv0v0b2EFog/viewform"
          >
            <div>
              <div class="feature__icon">
                <SvgVerifiedIcon />
              </div>
              <h4>Get Listed</h4>
              <p>
                List your DeFi token or NFT collection to make its bubble map
                publicly accessible. Increase your visibility by exposing your
                project to our 100,000 unique monthly visitors.
              </p>
            </div>
            <div class="feature__action">
              Apply for Listing
              <div class="feature__action-icon">
                <SvgRightArrowIcon />
              </div>
            </div>
          </a>
        </div>
        <div
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6 feature"
        >
          <a
            target="_blank"
            href="https://gist.github.com/joel-moonlight/070e7f406e2ba7a8f2b1609de835a1c4"
          >
            <div>
              <div class="feature__icon">
                <SvgCodeIcon />
              </div>
              <h4>Iframe Integration</h4>
              <p>
                Integrate pre-built, time-tested iframes that already powers
                leading decentralized applications. Simple and customisable.
              </p>
            </div>
            <div class="feature__action">
              View Documentation
              <div class="feature__action-icon">
                <SvgRightArrowIcon />
              </div>
            </div>
          </a>
        </div>
        <div
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6 feature"
        >
          <a target="_blank" href="mailto:api@bubblemaps.io">
            <div>
              <div class="feature__icon">
                <SvgDataIcon />
              </div>
              <h4>API</h4>
              <p>
                Use our graph data for your own needs and derive custom metrics
                from it.
              </p>
            </div>
            <div class="feature__action">
              Get in Touch
              <div class="feature__action-icon">
                <SvgRightArrowIcon />
              </div>
            </div>
          </a>
        </div>
        <div
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6 feature"
        >
          <a target="_blank" href="mailto:contact@bubblemaps.io">
            <div>
              <div class="feature__icon">
                <SvgNetworkIcon />
              </div>
              <h4>Add your Blockchain</h4>
              <p>
                Bubblemaps is available on ETH, BNB Chain, and can expand to any
                chain with the appropriate data sources.
              </p>
            </div>
            <div class="feature__action">
              Contact Us
              <div class="feature__action-icon">
                <SvgRightArrowIcon />
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/atoms/material/Button.vue";

export default {
  name: "WorkWithUs",
  components: {
    Button,
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/variables";

.header {
  @include theme-almost-black-gradient(45deg);
  margin-top: -56px;
  padding-top: 56px;
}

@media (min-width: $mdc-tablet) {
  .header {
    margin-top: -64px;
    padding-top: 64px;
  }
}

.title {
  text-align: center;
  padding-top: 70px;
  padding-bottom: 70px;

  h1 {
    margin-top: 0;
    margin-bottom: 1rem;
    @include scaling-title(2.1rem, 540px, 400px);
  }

  h4 {
    margin-bottom: 30px;
  }
}

@media (max-width: $mdc-tablet) {
  .title {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.workwithus {
  padding-top: 70px;
  padding-bottom: 70px;
  background: url("../assets/backgrounds/background2.svg") no-repeat center;
  background-size: cover;

  .workwithus__features {
    max-width: 1000px;
    margin: auto;
  }

  .feature {
    @include theme-glass-effect;
    padding: 20px;
    height: 100%;
    box-sizing: border-box;
    transition: transform 200ms ease-in-out;

    a {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      .feature__icon {
        border: 1px solid grey;
        width: 50px;
        height: 50px;
        border-radius: 100px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          fill: white;
          height: 30px;
        }
      }

      .feature__action {
        color: $theme-blue;
        display: flex;
        align-items: center;

        .feature__action-icon {
          background-color: $theme-blue;
          height: 20px;
          width: 30px;
          border-radius: 10px;
          margin-left: 10px;
          position: relative;
          top: 1px;

          svg {
            height: 20px;
            position: relative;
            left: 4px;
            fill: white;
            transition: transform 200ms ease-in-out;
          }
        }
      }
    }

    &:hover {
      transform: translateY(-10px);

      a {
        .feature__action {
          .feature__action-icon {
            svg {
              transform: translateX(15px);
            }
          }
        }
      }
    }
  }
}

@media (min-width: $mdc-grid-tablet) and (max-width: calc($mdc-grid-desktop - 1px)) {
  .workwithus {
    .feature {
      grid-column-start: 2;
    }
  }
}
</style>
