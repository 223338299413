<template>
  <a class="tweet" target="_blank" :href="tweet_link">
    <div class="tweet__header">
      <div class="tweet__profile">
        <img
          :alt="name + ' logo'"
          :src="require(`@/assets/logos/${image_name}`)"
          class="tweet__pp"
        />
        <div class="tweet__text">
          <div class="tweet__name">
            <div>{{ name }}</div>
            <SvgTwitterVerifiedIcon v-if="is_verified" :gold="is_gold" />
          </div>
          <div class="tweet__handle">{{ handle }}</div>
        </div>
      </div>
      <SvgTwitterLogo class="tweet__twitter-logo" />
    </div>
    <p class="tweet__content">
      <slot></slot>
    </p>
  </a>
</template>

<script>
export default {
  name: "Tweet",
  props: {
    tweet_link: String,
    image_name: String,
    name: String,
    handle: String,
    is_verified: Boolean,
    is_gold: Boolean,
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/variables";

.tweet {
  display: block;
  background-color: #ffffff17;
  border-radius: 15px;
  padding: 15px;
  font-family: "TwitterChirp";
  transition: background-color 200ms linear;

  &:hover {
    background-color: #a34eba55;
  }

  .tweet__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .tweet__profile {
      display: flex;
      align-items: center;
      min-width: 0;

      .tweet__text {
        min-width: 0;
      }

      .tweet__pp {
        width: 40px;
        height: 40px;
        border-radius: 100px;
        margin-right: 8px;
      }

      .tweet__name {
        font-weight: bolder;
        display: flex;
        align-items: center;

        svg {
          height: 14px;
          margin-left: 5px;
          flex-shrink: 0;
        }
      }

      .tweet__handle {
        opacity: 0.6;
      }

      .tweet__name > div,
      .tweet__handle {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .tweet__twitter-logo {
      fill: white;
      height: 20px;
      flex-shrink: 0;
      margin-left: 4px;
    }
  }

  .tweet__content {
    padding-right: 5px;
    font-size: 0.95rem;
    margin-bottom: 0;
  }
}
</style>
