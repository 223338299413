<template>
  <div class="notfound">
    <div class="mdc-layout-grid">
      <div class="mdc-layout-grid__inner">
        <div
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12 notfound__text"
        >
          <h1>404</h1>
          <h3>Oops, this page doesn't exist.</h3>
          <p>
            Please double-check your link. If the problems persists, contact our
            <a target="_blank" href="mailto:help@bubblemaps.io">help desk</a>.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/variables";

.notfound {
  background: url("../assets/backgrounds/background1.svg") no-repeat center;
  background-size: cover;
  margin-top: -56px;
  padding-top: 56px;
}

@media (min-width: $mdc-tablet) {
  .notfound {
    margin-top: -64px;
    padding-top: 64px;
  }
}

.notfound__text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: calc(100vh - 350px);
  text-align: center;
  padding: 50px 0;

  h1 {
    font-weight: 800;
    font-size: 8em;
    margin: 0 0 30px 0;
  }
}
</style>
