<template>
  <Tweet
    tweet_link="https://twitter.com/openseapro/status/1654150865102553088"
    image_name="openseapro.png"
    name="OpenSea Pro"
    handle="@openseapro"
    is_verified
    is_gold
  >
    🤝&nbsp;OpenSea Pro Integration: Bubblemaps!<br /><br />Connect the dots
    between the top 150 holders of a collection by observing on-chain relations
    and activities. Happy whale spotting&nbsp;🔍
  </Tweet>
</template>

<script>
import Tweet from "@/components/atoms/Tweet.vue";

export default {
  components: {
    Tweet,
  },
};
</script>
