<template>
  <svg viewBox="0 0 240 200">
    <defs>
      <radialGradient
        id="radial-gradient"
        cx="121.26"
        cy="100"
        r="156.59"
        gradientTransform="translate(-.75)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#231d2f" />
        <stop offset="1" stop-color="#040308" />
      </radialGradient>
    </defs>
    <g id="Block_1" data-name="Block 1">
      <rect
        id="BG-2"
        data-name="BG"
        width="240"
        height="200"
        rx="25"
        fill="url(#radial-gradient)"
      />
      <path
        id="Dot_line"
        data-name="Dot line"
        class="cls-2"
        d="M36.85 89.14v24"
      />
      <path
        id="Dot_line-2"
        data-name="Dot line"
        class="cls-2"
        d="M107.35 34.64h33"
      />
      <rect
        id="Rectangle1"
        class="cls-3"
        x="22.6"
        y="17.15"
        width="89"
        height="74"
        rx="10"
      />
      <path
        id="Key1"
        class="cls-4"
        d="M40.62 28.89h-4a2.44 2.44 0 1 0 0 1.4h2.15v.8h1v-.8h.83Zm-6.3 1.74a1 1 0 1 1 1-1 1 1 0 0 1-1 1Z"
      />
      <rect
        id="Rectangle2"
        class="cls-3"
        x="128.4"
        y="17.15"
        width="89"
        height="74"
        rx="10"
      />
      <path
        id="Key2"
        class="cls-4"
        d="M146.27 28.89h-4a2.44 2.44 0 1 0 0 1.4h2.14v.8h1v-.8h.84ZM140 30.63a1 1 0 1 1 1-1 1 1 0 0 1-1 1Z"
      />
      <rect
        id="Rectangle3"
        class="cls-3"
        x="22.6"
        y="108.85"
        width="89"
        height="74"
        rx="10"
      />
      <path
        id="Key3"
        class="cls-4"
        d="M40.62 120.5h-4a2.44 2.44 0 1 0 0 1.4h2.15v.8h1v-.8h.83Zm-6.3 1.74a1 1 0 1 1 1-1 1 1 0 0 1-1 1Z"
      />
      <rect
        id="Rectangle4"
        class="cls-3"
        x="128.4"
        y="108.85"
        width="89"
        height="74"
        rx="10"
      />
      <path
        id="Key4"
        class="cls-4"
        d="M146.27 120.5h-4a2.44 2.44 0 1 0 0 1.4h2.14v.8h1v-.8h.84Zm-6.3 1.74a1 1 0 1 1 1-1 1 1 0 0 1-.97 1Z"
      />
      <text class="cls-5" transform="translate(31.23 43.37)">
        C
        <tspan class="cls-6" x="3.24" y="0">46</tspan>
        <tspan class="cls-7" x="9.73" y="0">23C8E</tspan>
        <tspan class="cls-6" x="25.95" y="0">56B71</tspan>
        <tspan x="42.17" y="0">E06B</tspan>
        <tspan class="cls-6" x="55.14" y="0">BE1</tspan>
        <tspan x="0" y="6.36">00149A9CF4B58B6D4FEACE</tspan>
        <tspan x="0" y="12.72">4A</tspan>
        <tspan class="cls-6" x="6.49" y="12.72">78</tspan>
        <tspan x="12.97" y="12.72">3C0BB51DE03AABFC88</tspan>
        <tspan x="0" y="19.08">CD43</tspan>
        <tspan class="cls-8" x="12.97" y="19.08">801ABAA4</tspan>
        <tspan class="cls-9" x="38.92" y="19.08">96</tspan>
        <tspan x="45.41" y="19.08">2AA2AF</tspan>
        <tspan class="cls-6" x="64.87" y="19.08">85</tspan>
        <tspan x="0" y="25.44">78</tspan>
        <tspan class="cls-6" x="6.49" y="25.44">72</tspan>
        <tspan x="12.97" y="25.44">3C130F8A5B2B061A41</tspan>
        <tspan x="0" y="31.8">F7E687D</tspan>
        <tspan class="cls-6" x="22.71" y="31.8">2C</tspan>
        <tspan x="29.19" y="31.8">558D3</tspan>
        <tspan class="cls-8" x="45.41" y="31.8">C9F3</tspan>
        <tspan x="58.38" y="31.8">75AD</tspan>
        <tspan x="0" y="38.16">E3B4A144698496DA</tspan>
      </text>
      <text class="cls-10" transform="translate(45.93 31.41)">
        Block 0x4dc3...
      </text>
      <text class="cls-10" transform="translate(45.93 123.06)">
        Block 0x4dc3...
      </text>
      <text class="cls-10" transform="translate(151.71 123.06)">
        Block 0x4dc3...
      </text>
      <text class="cls-10" transform="translate(151.71 31.41)">
        Block 0x6b42...
      </text>
      <text
        transform="translate(31.03 134.74)"
        fill="#0ff"
        font-size="5.3"
        font-family='#9slide03spacemono,"#9Slide03 Space Mono"'
      >
        74C1
        <tspan class="cls-12" x="12.97" y="0">765</tspan>
        <tspan class="cls-6" x="22.71" y="0">351</tspan>
        <tspan class="cls-12" x="32.44" y="0">C2F38E651917</tspan>
        <tspan class="cls-12">
          <tspan x="0" y="6.36">29A9CF4B58B6</tspan>
          <tspan class="cls-9" x="38.92" y="6.36">D4F</tspan>
          <tspan x="48.65" y="6.36">EACE4A</tspan>
          <tspan class="cls-6" x="68.12" y="6.36">7</tspan>
        </tspan>
        <tspan class="cls-6">
          <tspan x="0" y="12.72">8</tspan>
          <tspan class="cls-12" x="3.24" y="12.72">3C0BB51DE03AABFC88CD4</tspan>
        </tspan>
        <tspan class="cls-12">
          <tspan x="0" y="19.08">3801ABAA49</tspan>
          <tspan class="cls-6" x="32.44" y="19.08">6</tspan>
          <tspan x="35.68" y="19.08">2AA2C</tspan>
          <tspan class="cls-6" x="51.9" y="19.08">46</tspan>
          <tspan x="58.38" y="19.08">23C8</tspan>
          <tspan x="0" y="25.44">E</tspan>
          <tspan class="cls-6" x="3.24" y="25.44">56B71</tspan>
          <tspan x="19.46" y="25.44">E06B</tspan>
          <tspan class="cls-6" x="32.44" y="25.44">BE1</tspan>
          <tspan x="42.17" y="25.44">00149A9CF</tspan>
          <tspan x="0" y="31.8">4B58B6</tspan>
          <tspan class="cls-6" x="19.46" y="31.8">D4F</tspan>
          <tspan x="29.19" y="31.8">EACE4A</tspan>
          <tspan class="cls-6" x="48.65" y="31.8">78</tspan>
          <tspan x="55.14" y="31.8">3C0BB</tspan>
          <tspan x="0" y="38.16">51DEO3AABFCA5BE</tspan>
        </tspan>
      </text>
      <text class="cls-5" transform="translate(136.89 134.74)">
        06B
        <tspan class="cls-9" x="9.73" y="0">BE1</tspan>
        <tspan x="19.46" y="0">00149A9CF</tspan>
        <tspan class="cls-6" x="48.65" y="0">4B58B6D</tspan>
        <tspan class="cls-6">
          <tspan x="0" y="6.36">4F</tspan>
          <tspan class="cls-12" x="6.49" y="6.36">EACE4A</tspan>
          <tspan x="25.95" y="6.36">78</tspan>
          <tspan class="cls-12" x="32.44" y="6.36">3C0BB51DEO3A</tspan>
        </tspan>
        <tspan x="0" y="12.72">ABFCA5BEDE03AABFC88CD4</tspan>
        <tspan x="0" y="19.08">3801ABAA4962AA2AF</tspan>
        <tspan class="cls-6" x="55.14" y="19.08">85</tspan>
        <tspan class="cls-7" x="61.63" y="19.08">78</tspan>
        <tspan class="cls-6" x="68.12" y="19.08">7</tspan>
        <tspan class="cls-6">
          <tspan x="0" y="25.44">2</tspan>
          <tspan class="cls-12" x="3.24" y="25.44">3C130F8A5B2B061A41F7E</tspan>
        </tspan>
        <tspan x="0" y="31.8">687D</tspan>
        <tspan class="cls-6" x="12.97" y="31.8">2C</tspan>
        <tspan x="19.46" y="31.8">558D3</tspan>
        <tspan class="cls-8" x="35.68" y="31.8">C9F3</tspan>
        <tspan x="48.65" y="31.8">75ADE3B</tspan>
        <tspan class="cls-8">
          <tspan x="0" y="38.16">4A144</tspan>
          <tspan class="cls-12" x="16.22" y="38.16">698496DD34</tspan>
        </tspan>
      </text>
      <text class="cls-5" transform="translate(137.09 43.37)">
        A144698496DA
        <tspan class="cls-9" x="38.92" y="0">F58A928</tspan>
        <tspan class="cls-7" x="61.63" y="0">1E7</tspan>
        <tspan x="0" y="6.36">4C1765</tspan>
        <tspan class="cls-6" x="19.46" y="6.36">351</tspan>
        <tspan x="29.19" y="6.36">C2F38E6519172</tspan>
        <tspan x="0" y="12.72">4A20ADE</tspan>
        <tspan class="cls-8" x="22.71" y="12.72">03AAB</tspan>
        <tspan class="cls-7" x="38.92" y="12.72">FC88C</tspan>
        <tspan class="cls-6" x="55.14" y="12.72">D438</tspan>
        <tspan x="68.12" y="12.72">0</tspan>
        <tspan x="0" y="19.08">1ABAA4</tspan>
        <tspan class="cls-6" x="19.46" y="19.08">96</tspan>
        <tspan x="25.95" y="19.08">2AA2AF</tspan>
        <tspan class="cls-6" x="45.41" y="19.08">85</tspan>
        <tspan x="51.9" y="19.08">78</tspan>
        <tspan class="cls-6" x="58.38" y="19.08">72</tspan>
        <tspan x="64.87" y="19.08">3C</tspan>
        <tspan x="0" y="25.44">130F8A5B2B061A41F7E687</tspan>
        <tspan x="0" y="31.8">D</tspan>
        <tspan class="cls-6" x="3.24" y="31.8">2C</tspan>
        <tspan class="cls-7" x="9.73" y="31.8">558D3</tspan>
        <tspan class="cls-8" x="25.95" y="31.8">C9F3</tspan>
        <tspan class="cls-7" x="38.92" y="31.8">738E651917</tspan>
        <tspan x="0" y="38.16">24A20A6CEB85B2E</tspan>
      </text>
    </g>
  </svg>
</template>

<script>
export default { name: "BlockchainScreen" };
</script>

<style scoped lang="scss">
.cls-2 {
  fill: none;
  stroke: #fff;
  stroke-linejoin: round;
  stroke-dasharray: 1 3;
}
.cls-3 {
  fill: #212121;
}
.cls-10,
.cls-4 {
  fill: #fff;
}
.cls-5 {
  font-size: 5.3px;
  font-family: monospace;
}
.cls-12,
.cls-5 {
  fill: #434343;
}
.cls-6,
.cls-9 {
  fill: #c9daf8;
}
.cls-7,
.cls-9 {
  letter-spacing: 0;
}
.cls-8 {
  fill: #0ff;
}
.cls-10 {
  font-size: 6.2px;
  font-family: monospace;
  font-weight: 700;
}
</style>
