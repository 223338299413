import { createStore } from "vuex";

export default createStore({
  state: {
    db: null,
  },
  mutations: {
    SET_DB(state, db) {
      state.db = db;
    },
  },
  actions: {
    init_metadata({ commit }, db) {
      commit("SET_DB", db);
    },
  },
  modules: {},
  getters: {
    get_db(state) {
      return state.db;
    },
  },
});
