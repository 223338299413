<template>
  <svg viewBox="0 0 48 48">
    <path
      d="M9 45.15q-1.75 0-2.95-1.2-1.2-1.2-1.2-2.95v-6.65q0-1.75 1.2-2.95 1.2-1.2 2.95-1.2h2.15v-3.55q0-1.95 1.375-3.325Q13.9 21.95 15.85 21.95h6.1V17.8H19.8q-1.75 0-2.95-1.2-1.2-1.2-1.2-2.95V7q0-1.75 1.2-2.95 1.2-1.2 2.95-1.2h8.4q1.75 0 2.95 1.2 1.2 1.2 1.2 2.95v6.65q0 1.75-1.2 2.95-1.2 1.2-2.95 1.2h-2.15v4.15h6.1q1.95 0 3.325 1.375Q36.85 24.7 36.85 26.65v3.55H39q1.75 0 2.95 1.2 1.2 1.2 1.2 2.95V41q0 1.75-1.2 2.95-1.2 1.2-2.95 1.2h-8.15q-1.75 0-2.95-1.2-1.2-1.2-1.2-2.95v-6.65q0-1.75 1.2-2.95 1.2-1.2 2.95-1.2h1.85v-4.15H15.3v4.15h1.85q1.75 0 2.95 1.2 1.2 1.2 1.2 2.95V41q0 1.75-1.2 2.95-1.2 1.2-2.95 1.2Zm10.8-31.5h8.4V7h-8.4ZM9 41h8.15v-6.65H9Zm21.85 0H39v-6.65h-8.15ZM24 13.65Zm-6.85 20.7Zm13.7 0Z"
    />
  </svg>
</template>

<script>
export default { name: "NetworkIcon" };
</script>
