<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    version="1.1"
    viewBox="0 0 32 32"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <path
      d="M29.844,24C31.215,21.645,32,18.922,32,16c0-8.836-7.164-16-16-16S0,7.164,0,16s7.164,16,16,16    c4.418,0,8.418-1.789,11.312-4.688L24.48,24.48C22.309,26.656,19.309,28,16,28C9.383,28,4,22.617,4,16S9.383,4,16,4    s12,5.383,12,12c0,1.422-0.273,2.781-0.73,4.047L27.238,20H24V8.043h-8c-4.418,0-8,3.582-8,8s3.582,8,8,8    c1.836,0,3.508-0.645,4.859-1.684C21.586,23.328,22.695,24,24,24H29.844z M20,14.008v2.035c0,2.207-1.797,4-4,4s-4-1.793-4-4    s1.797-4,4-4h4V14.008z"
    />
  </svg>
</template>

<script>
export default { name: "AtIcon" };
</script>
