<template>
  <div class="home__header">
    <img
      alt=""
      src="@/assets/motifs/motif1.svg"
      class="home__motif --1 noselect"
    />
    <img
      alt=""
      src="@/assets/clusters/cluster1.svg"
      class="home__motif --2 noselect"
    />
    <img
      alt=""
      src="@/assets/clusters/cluster2.svg"
      class="home__motif --3 noselect"
    />
    <img
      alt=""
      src="@/assets/motifs/motif3.svg"
      class="home__motif --4 noselect"
    />
    <div class="mdc-layout-grid">
      <div class="mdc-layout-grid__inner">
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
          <Header />
        </div>
      </div>
    </div>
  </div>
  <div class="home__strip">
    <div class="mdc-layout-grid">
      <div class="mdc-layout-grid__inner">
        <div
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12 home__section"
        >
          <TrustedBy />
        </div>
      </div>
    </div>
  </div>
  <div class="home_middle">
    <div class="mdc-layout-grid">
      <div class="mdc-layout-grid__inner home__section">
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
          <AppIntro />
        </div>
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
          <Partners />
        </div>
      </div>
    </div>
  </div>
  <div class="home__bottom">
    <div class="mdc-layout-grid">
      <div class="mdc-layout-grid__inner">
        <div
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12 home__section home__testimonials"
        >
          <Testimonials />
        </div>
      </div>
      <div class="home__section home__form">
        <SubscribeForm />
      </div>
    </div>
  </div>
</template>

<script>
import SubscribeForm from "@/components/molecules/SubscribeForm.vue";
import Testimonials from "@/components/organisms/Testimonials/Testimonials.vue";
import TrustedBy from "@/components/molecules/TrustedBy.vue";
import AppIntro from "@/components/molecules/AppIntro.vue";
import Partners from "@/components/molecules/Partners.vue";
import Header from "@/components/organisms/Header/Header.vue";

export default {
  name: "Navigation",
  components: {
    SubscribeForm,
    Testimonials,
    TrustedBy,
    AppIntro,
    Header,
    Partners,
  },
  methods: {
    openVideo() {
      this.$refs.videoDialog.open();
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/variables";

.home__section {
  padding: 60px 0;
}

.home__header {
  background: url("../assets/backgrounds/background1.svg") no-repeat center;
  background-size: cover;
  margin-top: -56px;
  padding-top: 56px;
  position: relative;
  overflow: hidden;

  .mdc-layout-grid {
    position: relative;
    z-index: 1;
  }
}

@media (min-width: $mdc-tablet) {
  .home__header {
    margin-top: -64px;
    padding-top: 64px;
  }
}

.home__bottom {
  background: url("../assets/backgrounds/background2.svg") no-repeat center;
  background-size: cover;
}

.home__motif {
  position: absolute;
  opacity: 0.6;
  z-index: 0;

  &.--1 {
    bottom: -86px;
    right: -993px;
    height: 719px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    mask-image: linear-gradient(16deg, rgba(0, 0, 0, 1) 50%, transparent);
  }

  &.--2 {
    height: 102px;
    bottom: 586px;
    right: -40px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    mask-image: linear-gradient(16deg, rgba(0, 0, 0, 1) 50%, transparent);
  }

  &.--3 {
    height: 100px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    top: 130px;
    mask-image: linear-gradient(311deg, rgb(0, 0, 0), #000000a6);
  }

  &.--4 {
    height: 533px;
    bottom: -354px;
    left: -133px;
    transform: rotate(1deg) scaleX(-1);
    mask-image: linear-gradient(24deg, rgba(0, 0, 0, 1) 50%, transparent);
  }
}

@media (max-width: 1229px) {
  .home__motif {
    &.--1 {
      bottom: -100px;
      left: -450px;
      height: 400px;
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
      right: auto;
    }

    &.--2 {
      bottom: 0;
      left: 87px;
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
      right: auto;
    }

    &.--3 {
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
      right: 0;
    }

    &.--4 {
      display: none;
    }
  }
}

@media (max-width: $mdc-grid-tablet) {
  .home__motif {
    &.--1 {
      bottom: -65px;
      left: -354px;
      height: 300px;
    }

    &.--2 {
      height: 75px;
      bottom: 10px;
      left: 51px;
    }

    &.--3 {
      right: 0;
    }
  }
}

.home__testimonials {
  padding-bottom: 0;
}

.home__form {
  max-width: 1000px;
  margin: auto;
}

.home__strip {
  background-color: $theme-almost-black;
}

.home_middle {
  background: url("../assets/backgrounds/background2.svg") no-repeat center;
  background-size: cover;
}
</style>
