<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 200">
    <g id="Block2">
      <rect
        id="BG-2"
        data-name="BG"
        class="cls-1"
        width="240"
        height="200"
        rx="25"
      />
      <g id="Elements">
        <path
          class="cls-2"
          d="M18,61.43h0A2.51,2.51,0,0,1,20.58,59h8.84a2.68,2.68,0,0,1,1.82.71A2.34,2.34,0,0,1,32,61.43v1.14h0A2.51,2.51,0,0,1,29.42,65H20.61A2.61,2.61,0,0,1,18,62.39Z"
        />
        <path
          class="cls-2"
          d="M18,75.43h0A2.51,2.51,0,0,1,20.58,73h8.84a2.68,2.68,0,0,1,1.82.71A2.34,2.34,0,0,1,32,75.43v1.14h0A2.51,2.51,0,0,1,29.42,79H20.61A2.61,2.61,0,0,1,18,76.39Z"
        />
        <path
          class="cls-2"
          d="M18,89.43h0A2.51,2.51,0,0,1,20.58,87h8.84a2.68,2.68,0,0,1,1.82.71A2.34,2.34,0,0,1,32,89.43v1.14h0A2.51,2.51,0,0,1,29.42,93H20.61A2.61,2.61,0,0,1,18,90.39Z"
        />
        <path
          class="cls-2"
          d="M18,104.43h0A2.51,2.51,0,0,1,20.58,102h8.84a2.68,2.68,0,0,1,1.82.71,2.34,2.34,0,0,1,.76,1.72v1.14h0A2.51,2.51,0,0,1,29.42,108H20.61A2.61,2.61,0,0,1,18,105.39Z"
        />
        <path
          class="cls-2"
          d="M18,118.43h0A2.51,2.51,0,0,1,20.58,116h8.84a2.68,2.68,0,0,1,1.82.71,2.34,2.34,0,0,1,.76,1.72v1.14h0A2.51,2.51,0,0,1,29.42,122H20.61A2.61,2.61,0,0,1,18,119.39Z"
        />
        <path
          class="cls-3"
          d="M39,61.43h0A2.53,2.53,0,0,1,41.61,59H77.39a2.75,2.75,0,0,1,1.85.71A2.38,2.38,0,0,1,80,61.43v1.14h0A2.53,2.53,0,0,1,77.39,65H41.61A2.53,2.53,0,0,1,39,62.57Z"
        />
        <path
          class="cls-4"
          d="M89,61.43h0A2.52,2.52,0,0,1,91.59,59h28.82a2.68,2.68,0,0,1,1.83.71,2.34,2.34,0,0,1,.76,1.72v1A2.61,2.61,0,0,1,120.39,65H91.61A2.61,2.61,0,0,1,89,62.39Z"
        />
        <path
          class="cls-4"
          d="M40,75.43h0A2.52,2.52,0,0,1,42.59,73H87.41a2.68,2.68,0,0,1,1.83.71A2.34,2.34,0,0,1,90,75.43v1A2.61,2.61,0,0,1,87.39,79H42.59A2.52,2.52,0,0,1,40,76.57Z"
        />
        <path
          class="cls-5"
          d="M96,75.43h0A2.57,2.57,0,0,1,98.69,73h5.62a2.86,2.86,0,0,1,1.9.71,2.3,2.3,0,0,1,.79,1.72v1A2.61,2.61,0,0,1,104.39,79H98.61A2.61,2.61,0,0,1,96,76.39Z"
        />
        <path
          class="cls-2"
          d="M40,89.43h0A2.52,2.52,0,0,1,42.59,87H71.41a2.68,2.68,0,0,1,1.83.71A2.34,2.34,0,0,1,74,89.43v1A2.61,2.61,0,0,1,71.39,93H42.61A2.61,2.61,0,0,1,40,90.39Z"
        />
        <path
          class="cls-2"
          d="M39,104.43h0A2.53,2.53,0,0,1,41.61,102H77.39a2.75,2.75,0,0,1,1.85.71,2.38,2.38,0,0,1,.76,1.72v1.14h0A2.53,2.53,0,0,1,77.39,108H41.61A2.53,2.53,0,0,1,39,105.57Z"
        />
        <path
          class="cls-4"
          d="M84,104.43h0A2.57,2.57,0,0,1,86.69,102h5.62a2.86,2.86,0,0,1,1.9.71,2.3,2.3,0,0,1,.79,1.72v1A2.61,2.61,0,0,1,92.39,108H86.61A2.61,2.61,0,0,1,84,105.39Z"
        />
        <path
          class="cls-6"
          d="M39,118.43h0A2.53,2.53,0,0,1,41.61,116H52.39a2.73,2.73,0,0,1,1.84.71,2.35,2.35,0,0,1,.77,1.72v1.14h0A2.53,2.53,0,0,1,52.39,122H41.61A2.61,2.61,0,0,1,39,119.39Z"
        />
        <path
          class="cls-2"
          d="M134,61.43h0A2.51,2.51,0,0,1,136.58,59h8.84a2.68,2.68,0,0,1,1.82.71,2.34,2.34,0,0,1,.76,1.72v1.14h0A2.51,2.51,0,0,1,145.42,65h-8.81A2.61,2.61,0,0,1,134,62.39Z"
        />
        <path
          class="cls-2"
          d="M134,75.43h0A2.51,2.51,0,0,1,136.58,73h8.84a2.68,2.68,0,0,1,1.82.71,2.34,2.34,0,0,1,.76,1.72v1.14h0A2.51,2.51,0,0,1,145.42,79h-8.81A2.61,2.61,0,0,1,134,76.39Z"
        />
        <path
          class="cls-2"
          d="M134,89.43h0A2.51,2.51,0,0,1,136.58,87h8.84a2.68,2.68,0,0,1,1.82.71,2.34,2.34,0,0,1,.76,1.72v1.14h0A2.51,2.51,0,0,1,145.42,93h-8.81A2.61,2.61,0,0,1,134,90.39Z"
        />
        <path
          class="cls-2"
          d="M134,104.43h0a2.51,2.51,0,0,1,2.58-2.43h8.84a2.68,2.68,0,0,1,1.82.71,2.34,2.34,0,0,1,.76,1.72v1.14h0a2.51,2.51,0,0,1-2.58,2.43h-8.81a2.61,2.61,0,0,1-2.61-2.61Z"
        />
        <path
          class="cls-2"
          d="M134,118.43h0a2.51,2.51,0,0,1,2.58-2.43h8.84a2.68,2.68,0,0,1,1.82.71,2.34,2.34,0,0,1,.76,1.72v1.14h0a2.51,2.51,0,0,1-2.58,2.43h-8.81a2.61,2.61,0,0,1-2.61-2.61Z"
        />
        <path
          class="cls-5"
          d="M156,61.43h0A2.53,2.53,0,0,1,158.61,59h35.78a2.75,2.75,0,0,1,1.85.71,2.38,2.38,0,0,1,.76,1.72v1.14h0A2.53,2.53,0,0,1,194.39,65H158.61A2.53,2.53,0,0,1,156,62.57Z"
        />
        <path
          class="cls-3"
          d="M206,61.43h0A2.5,2.5,0,0,1,208.57,59h12.86a2.7,2.7,0,0,1,1.82.71,2.37,2.37,0,0,1,.75,1.72v1A2.61,2.61,0,0,1,221.39,65H208.61A2.61,2.61,0,0,1,206,62.39Z"
        />
        <path
          class="cls-2"
          d="M156,75.43h0A2.52,2.52,0,0,1,158.59,73h44.82a2.68,2.68,0,0,1,1.83.71,2.34,2.34,0,0,1,.76,1.72v1A2.61,2.61,0,0,1,203.39,79h-44.8A2.52,2.52,0,0,1,156,76.57Z"
        />
        <path
          class="cls-2"
          d="M212,75.43h0A2.57,2.57,0,0,1,214.69,73h5.62a2.86,2.86,0,0,1,1.9.71,2.3,2.3,0,0,1,.79,1.72v1A2.61,2.61,0,0,1,220.39,79h-5.78A2.61,2.61,0,0,1,212,76.39Z"
        />
        <path
          class="cls-4"
          d="M156,89.43h0A2.52,2.52,0,0,1,158.59,87h28.82a2.68,2.68,0,0,1,1.83.71,2.34,2.34,0,0,1,.76,1.72v1A2.61,2.61,0,0,1,187.39,93H158.61A2.61,2.61,0,0,1,156,90.39Z"
        />
        <path
          class="cls-4"
          d="M156,104.43h0a2.53,2.53,0,0,1,2.61-2.43h35.78a2.75,2.75,0,0,1,1.85.71,2.38,2.38,0,0,1,.76,1.72v1.14h0a2.53,2.53,0,0,1-2.61,2.43H158.61a2.53,2.53,0,0,1-2.61-2.43Z"
        />
        <path
          class="cls-6"
          d="M200,104.43h0a2.57,2.57,0,0,1,2.69-2.43h5.62a2.86,2.86,0,0,1,1.9.71,2.3,2.3,0,0,1,.79,1.72v1a2.61,2.61,0,0,1-2.61,2.61h-5.78a2.61,2.61,0,0,1-2.61-2.61Z"
        />
        <path
          class="cls-4"
          d="M156,118.43h0a2.53,2.53,0,0,1,2.61-2.43h10.78a2.73,2.73,0,0,1,1.84.71,2.35,2.35,0,0,1,.77,1.72v1.14h0a2.53,2.53,0,0,1-2.61,2.43H158.61a2.61,2.61,0,0,1-2.61-2.61Z"
        />
        <path
          class="cls-2"
          d="M18,131.43h0A2.51,2.51,0,0,1,20.58,129h8.84a2.68,2.68,0,0,1,1.82.71,2.34,2.34,0,0,1,.76,1.72v1.14h0A2.51,2.51,0,0,1,29.42,135H20.61A2.61,2.61,0,0,1,18,132.39Z"
        />
        <path
          class="cls-2"
          d="M18,145.43h0A2.51,2.51,0,0,1,20.58,143h8.84a2.68,2.68,0,0,1,1.82.71,2.34,2.34,0,0,1,.76,1.72v1.14h0A2.51,2.51,0,0,1,29.42,149H20.61A2.61,2.61,0,0,1,18,146.39Z"
        />
        <path
          class="cls-2"
          d="M18,160.43h0A2.51,2.51,0,0,1,20.58,158h8.84a2.68,2.68,0,0,1,1.82.71,2.34,2.34,0,0,1,.76,1.72v1.14h0A2.51,2.51,0,0,1,29.42,164H20.61A2.61,2.61,0,0,1,18,161.39Z"
        />
        <path
          class="cls-2"
          d="M18,174.43h0A2.51,2.51,0,0,1,20.58,172h8.84a2.68,2.68,0,0,1,1.82.71,2.34,2.34,0,0,1,.76,1.72v1.14h0A2.51,2.51,0,0,1,29.42,178H20.61A2.61,2.61,0,0,1,18,175.39Z"
        />
        <path
          class="cls-2"
          d="M40,131.43h0A2.52,2.52,0,0,1,42.59,129H87.41a2.68,2.68,0,0,1,1.83.71,2.34,2.34,0,0,1,.76,1.72v1A2.61,2.61,0,0,1,87.39,135H42.59A2.52,2.52,0,0,1,40,132.57Z"
        />
        <path
          class="cls-5"
          d="M96,131.43h0A2.57,2.57,0,0,1,98.69,129h5.62a2.86,2.86,0,0,1,1.9.71,2.3,2.3,0,0,1,.79,1.72v1a2.61,2.61,0,0,1-2.61,2.61H98.61A2.61,2.61,0,0,1,96,132.39Z"
        />
        <path
          class="cls-4"
          d="M40,145.43h0A2.52,2.52,0,0,1,42.59,143H71.41a2.68,2.68,0,0,1,1.83.71,2.34,2.34,0,0,1,.76,1.72v1A2.61,2.61,0,0,1,71.39,149H42.61A2.61,2.61,0,0,1,40,146.39Z"
        />
        <path
          class="cls-2"
          d="M39,160.43h0A2.53,2.53,0,0,1,41.61,158H77.39a2.75,2.75,0,0,1,1.85.71,2.38,2.38,0,0,1,.76,1.72v1.14h0A2.53,2.53,0,0,1,77.39,164H41.61A2.53,2.53,0,0,1,39,161.57Z"
        />
        <path
          class="cls-4"
          d="M84,160.43h0A2.57,2.57,0,0,1,86.69,158h5.62a2.86,2.86,0,0,1,1.9.71,2.3,2.3,0,0,1,.79,1.72v1A2.61,2.61,0,0,1,92.39,164H86.61A2.61,2.61,0,0,1,84,161.39Z"
        />
        <path
          class="cls-6"
          d="M39,174.43h0A2.53,2.53,0,0,1,41.61,172H52.39a2.73,2.73,0,0,1,1.84.71,2.35,2.35,0,0,1,.77,1.72v1.14h0A2.53,2.53,0,0,1,52.39,178H41.61A2.61,2.61,0,0,1,39,175.39Z"
        />
        <path
          class="cls-2"
          d="M134,131.43h0a2.51,2.51,0,0,1,2.58-2.43h8.84a2.68,2.68,0,0,1,1.82.71,2.34,2.34,0,0,1,.76,1.72v1.14h0a2.51,2.51,0,0,1-2.58,2.43h-8.81a2.61,2.61,0,0,1-2.61-2.61Z"
        />
        <path
          class="cls-2"
          d="M134,145.43h0a2.51,2.51,0,0,1,2.58-2.43h8.84a2.68,2.68,0,0,1,1.82.71,2.34,2.34,0,0,1,.76,1.72v1.14h0a2.51,2.51,0,0,1-2.58,2.43h-8.81a2.61,2.61,0,0,1-2.61-2.61Z"
        />
        <path
          class="cls-2"
          d="M134,160.43h0a2.51,2.51,0,0,1,2.58-2.43h8.84a2.68,2.68,0,0,1,1.82.71,2.34,2.34,0,0,1,.76,1.72v1.14h0a2.51,2.51,0,0,1-2.58,2.43h-8.81a2.61,2.61,0,0,1-2.61-2.61Z"
        />
        <path
          class="cls-2"
          d="M134,174.43h0a2.51,2.51,0,0,1,2.58-2.43h8.84a2.68,2.68,0,0,1,1.82.71,2.34,2.34,0,0,1,.76,1.72v1.14h0a2.51,2.51,0,0,1-2.58,2.43h-8.81a2.61,2.61,0,0,1-2.61-2.61Z"
        />
        <path
          class="cls-4"
          d="M156,131.43h0a2.52,2.52,0,0,1,2.59-2.43h44.82a2.68,2.68,0,0,1,1.83.71,2.34,2.34,0,0,1,.76,1.72v1a2.61,2.61,0,0,1-2.61,2.61h-44.8a2.52,2.52,0,0,1-2.59-2.43Z"
        />
        <path
          class="cls-4"
          d="M212,131.43h0a2.57,2.57,0,0,1,2.69-2.43h5.62a2.86,2.86,0,0,1,1.9.71,2.3,2.3,0,0,1,.79,1.72v1a2.61,2.61,0,0,1-2.61,2.61h-5.78a2.61,2.61,0,0,1-2.61-2.61Z"
        />
        <path
          class="cls-4"
          d="M156,145.43h0a2.52,2.52,0,0,1,2.59-2.43h28.82a2.68,2.68,0,0,1,1.83.71,2.34,2.34,0,0,1,.76,1.72v1a2.61,2.61,0,0,1-2.61,2.61H158.61a2.61,2.61,0,0,1-2.61-2.61Z"
        />
        <path
          class="cls-3"
          d="M156,160.43h0a2.53,2.53,0,0,1,2.61-2.43h35.78a2.75,2.75,0,0,1,1.85.71,2.38,2.38,0,0,1,.76,1.72v1.14h0a2.53,2.53,0,0,1-2.61,2.43H158.61a2.53,2.53,0,0,1-2.61-2.43Z"
        />
        <path
          class="cls-4"
          d="M200,160.43h0a2.57,2.57,0,0,1,2.69-2.43h5.62a2.86,2.86,0,0,1,1.9.71,2.3,2.3,0,0,1,.79,1.72v1a2.61,2.61,0,0,1-2.61,2.61h-5.78a2.61,2.61,0,0,1-2.61-2.61Z"
        />
        <path
          class="cls-4"
          d="M156,174.43h0a2.53,2.53,0,0,1,2.61-2.43h10.78a2.73,2.73,0,0,1,1.84.71,2.35,2.35,0,0,1,.77,1.72v1.14h0a2.53,2.53,0,0,1-2.61,2.43H158.61a2.61,2.61,0,0,1-2.61-2.61Z"
        />
      </g>
      <g id="Logo">
        <path
          class="cls-7"
          d="M41.46,22.57h4.9v1.05h-6.1v-8.4h5.93v1H41.46Zm-.11-3.73h4.32v1H41.35Z"
        />
        <path
          class="cls-7"
          d="M47.08,18.2v-.94h4.05v.94Zm3.09,5.49a2,2,0,0,1-1.49-.52,2,2,0,0,1-.52-1.47V15.86h1.15v5.79a1.08,1.08,0,0,0,.26.79,1,1,0,0,0,.73.28,1.36,1.36,0,0,0,.9-.3l.36.82a1.48,1.48,0,0,1-.63.34A2.68,2.68,0,0,1,50.17,23.69Z"
        />
        <path
          class="cls-7"
          d="M56.35,17.2a3,3,0,0,1,1.38.3,2.2,2.2,0,0,1,.93.91A3.24,3.24,0,0,1,59,20v3.66H57.84V20.09a1.92,1.92,0,0,0-.45-1.39,1.65,1.65,0,0,0-1.27-.47,2.24,2.24,0,0,0-1.06.24,1.62,1.62,0,0,0-.71.71,2.52,2.52,0,0,0-.24,1.16v3.28H53V14.71h1.15V19l-.23-.45a2.27,2.27,0,0,1,1-1A3.17,3.17,0,0,1,56.35,17.2Z"
        />
        <path
          class="cls-7"
          d="M64,23.69a3.67,3.67,0,0,1-1.79-.42A3,3,0,0,1,61,22.12a3.23,3.23,0,0,1-.43-1.68A3.37,3.37,0,0,1,61,18.76a3.07,3.07,0,0,1,1.14-1.15,3.5,3.5,0,0,1,3.27,0,2.86,2.86,0,0,1,1.11,1.15,3.53,3.53,0,0,1,.41,1.72c0,.05,0,.11,0,.17a1.1,1.1,0,0,1,0,.18H61.44V20h4.81l-.47.29a2.07,2.07,0,0,0-.25-1.09,1.92,1.92,0,0,0-.72-.75,2.28,2.28,0,0,0-2.15,0,2,2,0,0,0-.73.75,2.39,2.39,0,0,0-.26,1.11v.2A2.24,2.24,0,0,0,62,21.63a2,2,0,0,0,.82.77,2.55,2.55,0,0,0,1.22.28,2.61,2.61,0,0,0,1-.19,2.2,2.2,0,0,0,.81-.58l.64.75a2.79,2.79,0,0,1-1.08.76A3.61,3.61,0,0,1,64,23.69Z"
        />
        <path
          class="cls-7"
          d="M68.47,23.62V17.26h1.11V19l-.11-.43a2,2,0,0,1,.89-1,3.09,3.09,0,0,1,1.53-.34v1.11h-.26a2,2,0,0,0-1.47.55,2.2,2.2,0,0,0-.54,1.6v3.17Z"
        />
        <path
          class="cls-7"
          d="M75.2,23.69a5,5,0,0,1-1.5-.22,3.34,3.34,0,0,1-1.12-.53l.48-.91a3.72,3.72,0,0,0,1,.47,4,4,0,0,0,1.23.19,2.17,2.17,0,0,0,1.16-.23.72.72,0,0,0,.35-.63.55.55,0,0,0-.22-.47,1.37,1.37,0,0,0-.57-.25,6.78,6.78,0,0,0-.78-.15c-.29-.05-.58-.1-.87-.17a3.38,3.38,0,0,1-.79-.29A1.41,1.41,0,0,1,73,20a1.56,1.56,0,0,1-.22-.89,1.64,1.64,0,0,1,.32-1,2.14,2.14,0,0,1,.92-.67,3.79,3.79,0,0,1,1.41-.23,5.57,5.57,0,0,1,1.27.15,3.19,3.19,0,0,1,1,.42l-.49.91a2.74,2.74,0,0,0-.9-.39,4.38,4.38,0,0,0-.94-.11,2,2,0,0,0-1.13.25.72.72,0,0,0-.37.63.57.57,0,0,0,.22.49,1.49,1.49,0,0,0,.58.27,7,7,0,0,0,.78.17c.29,0,.58.1.87.16a3.41,3.41,0,0,1,.78.29A1.38,1.38,0,0,1,78,21.8a1.56,1.56,0,0,1-.33,1,2.15,2.15,0,0,1-1,.65A4,4,0,0,1,75.2,23.69Z"
        />
        <path
          class="cls-7"
          d="M82.16,23.69a3.54,3.54,0,0,1-1.73-.42,3.06,3.06,0,0,1-1.19-1.15,3.24,3.24,0,0,1-.44-1.68,3.19,3.19,0,0,1,.44-1.68,3,3,0,0,1,1.19-1.15,3.54,3.54,0,0,1,1.73-.41,3.4,3.4,0,0,1,1.55.34,2.44,2.44,0,0,1,1.05,1l-.88.57a1.85,1.85,0,0,0-.76-.69,2.13,2.13,0,0,0-1-.23,2.26,2.26,0,0,0-1.11.28,2,2,0,0,0-.78.77A2.28,2.28,0,0,0,80,20.44a2.25,2.25,0,0,0,.29,1.18,2,2,0,0,0,.78.78,2.16,2.16,0,0,0,1.11.28,2.13,2.13,0,0,0,1-.22,2,2,0,0,0,.76-.69l.88.55a2.49,2.49,0,0,1-1.05,1A3.28,3.28,0,0,1,82.16,23.69Z"
        />
        <path
          class="cls-7"
          d="M88.1,23.69a3,3,0,0,1-1.24-.24,1.89,1.89,0,0,1-.82-.67,1.67,1.67,0,0,1-.29-1,1.76,1.76,0,0,1,.25-.94,1.69,1.69,0,0,1,.8-.67A3.71,3.71,0,0,1,88.31,20h2v.82h-2a1.74,1.74,0,0,0-1.17.29.94.94,0,0,0-.3.7.92.92,0,0,0,.37.75,1.69,1.69,0,0,0,1,.28,2.18,2.18,0,0,0,1.13-.29,1.62,1.62,0,0,0,.7-.84l.23.79a1.79,1.79,0,0,1-.8.9A2.92,2.92,0,0,1,88.1,23.69Zm2.09-.07V22.27L90.13,22V19.73a1.51,1.51,0,0,0-.42-1.14,1.85,1.85,0,0,0-1.28-.4,3.24,3.24,0,0,0-1.11.19,2.75,2.75,0,0,0-.91.5L85.93,18a3.55,3.55,0,0,1,1.18-.6,5,5,0,0,1,1.45-.21,3,3,0,0,1,2,.63,2.5,2.5,0,0,1,.7,2v3.84Z"
        />
        <path
          class="cls-7"
          d="M96.82,17.2a3,3,0,0,1,1.37.3,2.1,2.1,0,0,1,.93.91A3.23,3.23,0,0,1,99.46,20v3.66H98.3V20.09a1.92,1.92,0,0,0-.45-1.39,1.64,1.64,0,0,0-1.26-.47,2.25,2.25,0,0,0-1.07.24,1.6,1.6,0,0,0-.7.71,2.4,2.4,0,0,0-.25,1.16v3.28H93.42V17.26h1.1V19l-.18-.45a2.27,2.27,0,0,1,1-1A3.21,3.21,0,0,1,96.82,17.2Z"
        />
        <g id="Logo-2" data-name="Logo">
          <g id="etherscan-logo">
            <g id="circle">
              <g id="Group_3" data-name="Group 3">
                <path
                  id="Path_1"
                  data-name="Path 1"
                  class="cls-8"
                  d="M20.34,18.84a.61.61,0,0,1,.61-.61h1a.61.61,0,0,1,.61.61h0V22.7l.43-.1a.51.51,0,0,0,.39-.5V17.31A.61.61,0,0,1,24,16.7h1a.61.61,0,0,1,.61.61v4.44l.51-.2a.52.52,0,0,0,.31-.48v-5.3a.62.62,0,0,1,.62-.61h1a.61.61,0,0,1,.61.61v4.37a11.79,11.79,0,0,0,2.5-2.35,1,1,0,0,0,.16-1,7.23,7.23,0,0,0-13.65,4.75,6.39,6.39,0,0,0,.56,1.22.88.88,0,0,0,.87.45l.72-.07a.51.51,0,0,0,.45-.51V18.84"
                />
                <path
                  id="Path_2"
                  data-name="Path 2"
                  class="cls-9"
                  d="M20.32,25A7.22,7.22,0,0,0,31.8,19.18c0-.16,0-.33,0-.49-2.64,3.94-7.52,5.78-11.46,6.34"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Search-2" data-name="Search">
        <path
          id="Search_bar"
          data-name="Search bar"
          class="cls-10"
          d="M16,38.37h0A4.41,4.41,0,0,1,20.44,34h96.12a4.5,4.5,0,0,1,3.14,1.28,4.34,4.34,0,0,1,1.3,3.09v5.26h0A4.41,4.41,0,0,1,116.56,48H20.43A4.43,4.43,0,0,1,16,43.57Z"
        />
        <path
          id="Search-3"
          data-name="Search"
          d="M26.66,43.64c-.56-.55-1.11-1.1-1.66-1.66-.05,0-.06-.08,0-.13a3.15,3.15,0,0,0,.11-3.05,3.09,3.09,0,0,0-1.65-1.55,3.14,3.14,0,0,0-2.63.08A3.2,3.2,0,0,0,19.24,39a3.1,3.1,0,0,0,.1,2.61A3,3,0,0,0,21,43.18a3.13,3.13,0,0,0,3-.28c.08,0,.11,0,.17,0l1.69,1.69a.65.65,0,0,0,.92.05.76.76,0,0,0,.22-.39v-.16A.94.94,0,0,0,26.66,43.64Zm-4.45-1.26a2.17,2.17,0,1,1,2.18-2.16A2.16,2.16,0,0,1,22.21,42.38Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default { name: "EtherscanScreen" };
</script>

<style scoped lang="scss">
.cls-1 {
  fill: #f3f3f3;
}
.cls-2 {
  fill: #d9d9d9;
}
.cls-10,
.cls-2,
.cls-3,
.cls-4,
.cls-5,
.cls-6 {
  fill-rule: evenodd;
}
.cls-3 {
  fill: #999;
}
.cls-4 {
  fill: #fff;
}
.cls-5 {
  fill: #b7b7b7;
}
.cls-6 {
  fill: #666;
}
.cls-7 {
  fill: #383838;
}
.cls-8 {
  fill: #cecece;
}
.cls-8,
.cls-9 {
  isolation: isolate;
}
.cls-9 {
  fill: #696969;
}
.cls-10 {
  fill: #ccc;
}
</style>
